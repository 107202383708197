import React, { useEffect } from "react";
const dataPolling = async (callback, interval) => {
        useEffect(() => {
            
            let isActive = true;
            const poll = async () => {
            await callback(); 
            if (isActive) {
                setTimeout(poll, interval); 
            }
            };

            poll(); 
            return () => {
            isActive = false; 
            };
        }, []);
};
export { dataPolling };
