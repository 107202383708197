import React from 'react';
import '../styles/HeroSection.css';
import RegisterTransferForm from './RegisterTransferForm';
import RegisterWelcomeStatement from './RegisterWelcomeStatement';


const RegisterSection = () => {
  return (
   <>      
    <section className="dark-section flexing_content flex_container dark-section-login margin-top-20">
      <div className="column_60">
        <RegisterWelcomeStatement />
      </div>
      <div className="column_40 nopadding">
        <RegisterTransferForm />
      </div>
    </section>
   </> 
  );
};

export default RegisterSection;
