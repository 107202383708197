import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SumsubWebSdk from '@sumsub/websdk-react';
import '../styles/MainSection.css';
import backIcon from "../assets/back.png";
import profileBanner from "../assets/small-logo.png";
import rightArrow    from "../assets/right-arrow.png";
import Header from '../components/Header';
import Footer from '../components/Footer';
import IdentityVerification from '../components/verification'
import { getDataStorage } from '../helpers/storage';

function Profile() {   
  const navigate = useNavigate();
  const [kycaAcessToken, setKycaAcessToken] = useState("");
  const [loggedUser, setLoggedUser] = useState({});
  const [kycaAcessTokenLoading, setKycaAcessTokenLoading] = useState(false);
  const intFunc = async () => {
      
      setKycaAcessTokenLoading(true);
      const user      = await getDataStorage("loggedUser", false, true);
      const userToken = await getDataStorage("authToken", false, false);
      setLoggedUser(user);
  }
                                                      
  useEffect(() => {
    intFunc();
  }, [])   


  return (
    <div className="App">

       <Header />
       <IdentityVerification />
       <div>

        <div className="flexing_content flex_container margin-top-20 complete-main-body profile-content-header">
           <img src={profileBanner} alt="paul" />
           <h6><label>{`${loggedUser?.first_name ?? ""} ${loggedUser?.middle_name ?? ""} ${loggedUser?.last_name ?? ""}`}</label></h6>   
           <p><label>{loggedUser?.email}</label></p>               
        </div>

        <div className="flexing_content flex_container margin-top-20 complete-main-body profile-content-body">
          <div className="flexing_content flex_container margin-top-20 complete-main-body profile-content-body-inner">
              
          <section className="profile-content-body-inner-link">
            <div className="column_80_ profile-content-body-inner-link-details">
              <h3>Account</h3>
              <p>Personal details, invite friends, account limits, wallet statements, delete account</p>
            </div>
            <div className="column_20_ nopadding profile-content-body-inner-link-thebutton-verify">
              <button className="btn  btn-small-extende-right" onClick={(e) => goTo('/kyc/verification')}>
                <img src={rightArrow} alt="go" />
              </button>
            </div>
          </section>

          
          <section className="profile-content-body-inner-link">
            <div className="column_80_ profile-content-body-inner-link-details">
              <h3>Payment methods</h3>
              <p>Bank accounts, Mobile Number Accounts, Withdraw Wallets</p>
            </div>
            <div className="column_20_ nopadding profile-content-body-inner-link-thebutton-verify">
              <button className="btn  btn-small-extende-right" onClick={(e) => goTo('/kyc/verification')}>
                <img src={rightArrow} alt="go" />
              </button>
            </div>
          </section>

          
          <section className="profile-content-body-inner-link">
            <div className="column_80_ profile-content-body-inner-link-details">
              <h3>Security</h3>
              <p>2FA, Password</p>
            </div>
            <div className="column_20_ nopadding profile-content-body-inner-link-thebutton-verify">
              <button className="btn  btn-small-extende-right" onClick={(e) => goTo('/kyc/verification')}>
                <img src={rightArrow} alt="go" />
              </button>
            </div>
          </section>

          
          <section className="profile-content-body-inner-link">
            <div className="column_80_ profile-content-body-inner-link-details">
              <h3>Preferences</h3>
              <p>Notifications & display currency</p>
            </div>
            <div className="column_20_ nopadding profile-content-body-inner-link-thebutton-verify">
              <button className="btn  btn-small-extende-right" onClick={(e) => goTo('/kyc/verification')}>
                <img src={rightArrow} alt="go" />
              </button>
            </div>
          </section>

          
          <section className="profile-content-body-inner-link">
            <div className="column_80_ profile-content-body-inner-link-details">
              <h3>Support</h3>
              <p>Chat with us, FAQs, privacy policy, our blog, contact us</p>
            </div>
            <div className="column_20_ nopadding profile-content-body-inner-link-thebutton-verify">
              <button className="btn  btn-small-extende-right" onClick={(e) => goTo('/kyc/verification')}>
                <img src={rightArrow} alt="go" />
              </button>
            </div>
          </section>


          </div>                   
        </div> 
        
        <Footer />
       </div>
    </div>
  );
}

export default Profile;
