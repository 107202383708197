import React, {useState, useEffect} from 'react';
import '../styles/HeroSection.css';
import RegisterTransferForm from './RegisterTransferForm';
import RegisterWelcomeStatement from './RegisterWelcomeStatement';


const FooterCopyRights = () => {

  const [data, setDate] = useState("");
  useEffect(() => {
    setDate(new Date().getFullYear());
  }, [])

  
  return (
   <div className="red-border">      
      <div className="text-center-content-2_ red-border column_100">
        <p className="text-center red-border">
          Copy right &copy; {data} Peerpesa. All rights reserved
        </p> 
      </div>
   </div> 
  );
};

export default FooterCopyRights;
