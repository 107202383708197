import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, FormControl, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../styles/MainSection.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '16px',
  padding: theme.spacing(4),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: '#00C853', // Green color for labels
  marginBottom: theme.spacing(1),
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '8px',
  '& .MuiSelect-icon': {
    color: '#657792', // Matching the theme's gray color
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
    '&:hover fieldset': {
      borderColor: '#B0B0B0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#172C50',
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  color: 'white',
  padding: '14px',
  borderRadius: '8px',
  marginTop: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#2C2C2C',
  },
  '&.Mui-disabled': {
    backgroundColor: '#404040',
    color: '#808080',
  },
}));

const StepIndicator = styled(Typography)(({ theme }) => ({
  color: '#00C853',
  fontSize: '0.875rem',
  marginTop: theme.spacing(2),
}));

const ConversionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  color: 'white',
}));

const Deposit = () => {
  const [cryptoType, setCryptoType] = useState('USDT');
  const [amount, setAmount] = useState('');
  const [country, setCountry] = useState('UGX');
  const [paymentMethod, setPaymentMethod] = useState('mobile_money');
  const nav = useNavigate();

  const exchangeRates = {
    'USDT': {
      UGX: 3800, // 1 USDT = 3800 UGX
      KES: 120,  // 1 USDT = 120 KES
    }
  };

  const cryptoOptions = [
    { value: 'USDT', label: 'USDT', icon: '💱' },
  ];

  const countries = [
    { value: 'UGX', label: 'Uganda (UGX)', flag: '🇺🇬' },
    { value: 'KES', label: 'Kenya (KES)', flag: '🇰🇪' },
  ];

  const paymentMethods = [
    { value: 'mobile_money', label: 'Mobile Money' },
    { value: 'bank', label: 'Bank Transfer' },
  ];

  const calculateCryptoAmount = () => {
    if (!amount || !country) return '0';
    return (amount / exchangeRates[cryptoType][country]).toFixed(2);
  };

  const handleSubmit = () => {
    nav("/confirm-deposit")
  }
  return (
    <div className="App">
      <Header />

      <StyledContainer maxWidth="sm">
        <StyledBox>
          <Typography variant="h4" gutterBottom sx={{ color: '#172C50', fontWeight: 600, mb: 1 }}>
            Deposit Funds
          </Typography>
          <Typography variant="body1" sx={{ color: '#657792', mb: 4 }}>
            Convert your local currency to cryptocurrency instantly
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* First Row */}
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
              gap: 2 
            }}>
              <Box>
                <InputLabel>Select Stable Coin to Buy</InputLabel>
                <FormControl fullWidth>
                  <StyledSelect
                    value={cryptoType}
                    onChange={(e) => setCryptoType(e.target.value)}
                  >
                    {cryptoOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value} sx={{ color: '#172C50' }}>
                        {option.icon} {option.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Box>

              <Box>
                <InputLabel>Enter Amount</InputLabel>
                <StyledTextField
                  fullWidth
                  placeholder="Amount here"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                />
              </Box>
            </Box>

            {/* Second Row */}
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
              gap: 2 
            }}>
              <Box>
                <InputLabel>Select Your Country</InputLabel>
                <FormControl fullWidth>
                  <StyledSelect
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.value} value={country.value} sx={{ color: '#172C50' }}>
                        {country.flag} {country.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Box>

              <Box>
                <InputLabel>Method of Receipt</InputLabel>
                <FormControl fullWidth>
                  <StyledSelect
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    {paymentMethods.map((method) => (
                      <MenuItem key={method.value} value={method.value} sx={{ color: '#172C50' }}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Box>
            </Box>

            {amount && (
              <ConversionBox>
                <Typography variant="subtitle2" sx={{ color: '#00C853' }}>
                  You will receive approximately:
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600, my: 1 }}>
                  {calculateCryptoAmount()} {cryptoType}
                </Typography>
                <Typography variant="caption" sx={{ color: '#657792' }}>
                  Exchange rate: 1 {cryptoType} = {exchangeRates[cryptoType][country]} {country}
                </Typography>
              </ConversionBox>
            )}

            <StyledButton
              fullWidth
              variant="contained"
              disabled={!amount || !paymentMethod}
              onClick={() => handleSubmit()}
            >
              Next
            </StyledButton>

            <StepIndicator align="center">
              Step 1/3
            </StepIndicator>

            <Typography 
              variant="caption" 
              sx={{ 
                color: '#657792', 
                textAlign: 'center', 
                mt: 2,
                display: 'block'
              }}
            >
              The final amount of cryptocurrency may vary slightly due to market fluctuations.
              Transaction fees may apply depending on the payment method.
            </Typography>
          </Box>
        </StyledBox>
      </StyledContainer>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Deposit;
