import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import ThemeProvider from "./context/ThemeContext";
import AuthProvider from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";

import Home from "./pages/Home";
import About from "./pages/About";
import P2p from "./pages/P2p";
import Support from "./pages/Support";
import Careers from "./pages/Careers";
import Faqs from "./pages/Faqs";
import MoneyTransfer from "./pages/MoneyTransfer";
import BuyCrypto from "./pages/BuyCrypto";
import Exchange from "./pages/Exchange";

import CustomerProtectionPolicy from "./pages/CustomerProtectionPolicy";
import AMLPolicy from "./pages/AMLPolicy";
import TermsofService from "./pages/TermsofService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Login from "./pages/Login";
import Register from "./pages/Register";
import ConfirmAccount from "./pages/ConfirmAccount";
import ResetPassword from "./pages/ResetPassword";

import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";

import ProfileKyc from "./pages/ProfileKyc";
import KycConfirmVerification from "./pages/KycConfirmVerification";
import KycVerification from "./pages/KycVerification";

import Assests from "./pages/Assets";
import Wallets from "./pages/Wallets";
import Orders from "./pages/Orders";
import Transactions from "./pages/Transactions";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import Send from "./pages/Send";
import { getDataStorage } from "./helpers/storage";
import NotFound from "./pages/NotFound";
import ConfirmDeposit from "./pages/ConfirmDeposit";
import TransactionSuccess from "./pages/TransactionSuccess";
import ConfirmWithdraw from "./pages/ConfirmWithdraw";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authToken = await getDataStorage("authToken", true, false);
      setIsAuthenticated(authToken);
    };
    checkAuth();
  }, []);

  if (isAuthenticated === null ) {
    return <div className="loading-center"> Loading... </div>; // Prevents rendering before auth check is done
  }

  return (
    <>
      <Provider store={store}>
       <AuthProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/p2p" element={<P2p />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/login"
                element={isAuthenticated ? <Dashboard /> : <Login />}
              />
              <Route
                path="/register"
                element={isAuthenticated ? <Dashboard /> : <Register />}
              />
              <Route
                path="/confirm-account"
                element={isAuthenticated ? <Dashboard /> : <ConfirmAccount />}
              />
              <Route
                path="/reset-password"
                element={isAuthenticated ? <Dashboard /> : <ResetPassword />}
              />
              <Route
                path="/dashboard"
                element={isAuthenticated ? <Dashboard /> : <Home />}
              />
              <Route path="/money-transfer" element={<MoneyTransfer />} />
              <Route path="/buy-crypto" element={<BuyCrypto />} />
              <Route path="/swap" element={<Exchange />} />
              <Route path="/customer-protection" element={<CustomerProtectionPolicy />} />
              <Route path="/terms-of-service" element={<TermsofService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />

              <Route
                path="/settings"
                element={isAuthenticated ? <Settings /> : <Login />}
              />
              <Route
                path="/profile"
                element={isAuthenticated ? <Profile /> : <Login />}/>


              <Route
                path="/kyc"
                element={isAuthenticated ? <ProfileKyc /> : <Login />}/>
              <Route
                  path="/kyc/confirm/verification"
                  element={isAuthenticated ? <KycConfirmVerification /> : <Login />}/>
              <Route
                      path="/kyc/verification"
                      element={isAuthenticated ? <KycVerification /> : <Login />}/>
              <Route
                path="/assets"
                element={isAuthenticated ? <Assests /> : <Login />}
              />
              <Route
                path="/wallets"
                element={isAuthenticated ? <Wallets /> : <Login />}
              />
              <Route
                path="/orders"
                element={isAuthenticated ? <Orders /> : <Login />}
              />
              <Route
                path="/transactions"
                element={isAuthenticated ? <Transactions /> : <Login />}
              />
              <Route
                path="/deposit"
                element={isAuthenticated ? <Deposit /> : <Login />}
              />
              <Route
                path="/confirm-deposit"
                element={isAuthenticated ? <ConfirmDeposit /> : <Login />}
              />
              <Route
                path="/transaction-success"
                element={isAuthenticated ? <TransactionSuccess /> : <Login />}
              />
              <Route
                path="/withdraw"
                element={isAuthenticated ? <Withdraw /> : <Login />}
              />
              <Route
                path="/confirm-withdraw"
                element={isAuthenticated ? <ConfirmWithdraw /> : <Login />}
              />
              <Route path="/send" element={isAuthenticated ? <Send /> : <Send />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
       </AuthProvider>
      </Provider>
    </>
  );
};

export default App;
