// src/hooks/useCounter.js
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement, incrementByAmount } from '../store/slices/counterSlice';

export const useCounter = () => {
  const value = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  return {
    value,
    increment: () => dispatch(increment()),
    decrement: () => dispatch(decrement()),
    incrementByAmount: (amount) => dispatch(incrementByAmount(amount)),
  };
};
