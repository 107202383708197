import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/MainSection.css';
import '../styles/TransferForm.css';
import { RotatingLines, Rings, Oval } from "react-loader-spinner";
import Header from '../components/Header';
import Footer from '../components/Footer';
import backIcon from "../assets/back.png";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getCountirsList } from '../requests/requests';
import { getDataStorage } from '../helpers/storage';

function KycVerification() {   
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [componentData, setComponentData] = useState({
                                                          data: {
                                                                  country: "",
                                                                  country_of_residence: "",
                                                                  street_number_name: "",
                                                                  countriesList: [],
                                                                  selectedCountry: {},
                                                                  selectedCountryOfResidence: {}
                                                                }
                                                        });

                                                        
  const [kycaAcessToken, setKycaAcessToken] = useState("");
  const [loggedUser, setLoggedUser] = useState({});
  const [kycaAcessTokenLoading, setKycaAcessTokenLoading] = useState(false);
  const intFunc = async () => {
      
      setKycaAcessTokenLoading(true);
      const user      = await getDataStorage("loggedUser", false, true);
      const userToken = await getDataStorage("authToken", false, false);
      setLoggedUser(user);

      const countryList = await getCountirsList();
      if(countryList.status === true){
        const countriesList = "countriesList"; 
        setComponentData((prevState) => ({
          ...prevState,
          data: {
                 [countriesList]: countryList?.data ?? []
                }
          
        }));
      }
  }
                                                      
  useEffect(() => {
    intFunc();
  }, [])                                                        

                                                        
  const {
          register,
          handleSubmit,
          formState: { errors },
        } = useForm();
      
  const saveUserAddress = useMutation({
          mutationFn: (payLoad) => {
           
            setFormLoading(true);
            return registerAccount(payLoad);
          },
          onError: (error, variables, context) => {
            setFormLoading(false);
            const sendMsg = (error?.message !== undefined)? error?.message: "failed to place request. confirm network connection";
            return toast.error(sendMsg);
          },
          onSuccess: (data, variables, context) => {
            setFormLoading(false);
            if(data.status === false || data.status === 'error'){
              return toast.error(data?.message);
            } else {
              setComponentData((prevState) => ({
                ...prevState,
                data: {
                      ...prevState.data,
                      email: "",
                      password: "",
                      token: "",
                      confirm_password: ""
                    }
              }));
              return toast.success(data?.message);
            }
          },
  });
  const onUserEditLocation = (creds) => {

    return navigate('/kyc/confirm/verification');
    // tokenReset.saveUserAddress(creds);
  };


  const contentChange= (e, theKey, trimSpace = false) => {
    try{

      let { value } = e.target;
      if(trimSpace){
        value = value.trim().replace(/\s+/g, '');
      }

      setComponentData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [theKey]: value,
        },
      }));

    } catch(e){
      console.log(e)
    }
  }

  const contentCountryChange= (value, theKey, trimSpace = false) => {
    try{

      if(trimSpace){
        value = value.trim().replace(/\s+/g, '');
      }

      setComponentData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [theKey]: value,
        },
      }));

    } catch(e){
      console.log(e)
    }
  }

  // Custom option label with image and text
  const formatOptionLabel = ({ country_name, country_code, id }) => (
    <div style={{ display: 'flex', alignItems: 'left' }}>
       <span>{country_name}</span>
    </div>
  );
      

  return (
    <div className="App">
      <Header />
      <div>

        <div className="profile-verify-content-header">

          <div className="close-back clearfix group">
            <div className="close-back-icon dark-btn"  onClick={() => navigate('/profile')}>
              <img src={backIcon} alt="back" /> 
              <p>Back to profile</p>
            </div>
          </div>

          <div className="flexing_content flex_container margin-top-20">
            <h3>Enter your address</h3>  
            <p>To continue, kindly provide the address where you currently reside, exactly as it appears on your utility bill</p>               
          </div>
        </div>

        <div className="flexing_content flex_container margin-top-20 complete-main-body profile-content-body">
          <div className="flexing_content flex_container margin-top-20 complete-main-body profile-content-body-inner">
             

          <div className="form-inputs">
            <label> Country </label>
            <div className="selection-payment-method clearfix group inner-selection-payment-method">
            <Select className="selection-drop"
                    value={componentData.selectedCountry}
                    options={componentData.data.countriesList}
                    formatOptionLabel={formatOptionLabel}
                    name="country"
                    onChange={(selectedOption) => contentCountryChange(selectedOption, 'country')} 
                    placeholder="Country" />
            </div>
            {errors.country?.type === "required" && (
              <span className="main-input-error clearfix"> Country is required </span>
            )}
          </div>


          <div className="form-inputs margin-top-20">
            <label> Street number and name </label>
            <div className="selection-payment-method clearfix group inner-selection-payment-method">
            <input  type="text" 
                      className="selection-other"
                      value={componentData.data.token}
                      placeholder="Enter street number and name"
                      name="street_number_name"
                      {...register("street_number_name", {
                        required: false
                      })}
                      onChange={(e) => contentChange(e, 'street_number_name')}  />
            </div>
            {errors.password?.type === "required" && (
              <span className="main-input-error clearfix">  Street number and name is required </span>
            )}
          </div>

          <div className="form-inputs margin-top-20">
            <label> Country of Residence </label>
            <div className="selection-payment-method clearfix group inner-selection-payment-method">
              <Select className="selection-drop"
                    value={componentData.selectedCountryOfResidence}
                    options={componentData.data.countriesList}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(selectedOption) => contentCountryChange(selectedOption, 'country_of_residence')}
                    placeholder="Country of Residence" />
            </div>
            {errors.password?.type === "required" && (
              <span className="main-input-error clearfix">  Country of Residence  is required </span>
            )}
          </div>

          <div className="form-inputs margin-top-20">
            <button className="send-button button-full-width" onClick={handleSubmit(onUserEditLocation)}>
              <span  className={(formLoading)? 'hidden':''}>
                Save & Continue
              </span>
              <Oval
                    visible={formLoading}
                    height="20"
                    width="20"
                    color="#ffffff"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass="" />  
            </button>
          </div>  


          </div>
        </div>  

        <Footer />
      </div>
    </div>
  );
}

export default KycVerification;
