import React from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import ResetPasswordSection from '../components/ResetPasswordSection';

function ResetPassword() {
  return (
    <div className="App">
      <Header />
      <ResetPasswordSection />
    </div>
  );
}

export default ResetPassword;
