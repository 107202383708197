export const formatAmount = (amount, formatedNumber  = false) => {
    try{

        amount = parseFloat(amount);
        const locale = Intl.NumberFormat().resolvedOptions().locale;
        const options = { minimumFractionDigits: 0, maximumFractionDigits: 8 };
        const result = `${amount.toLocaleString(locale, options)}`;

        let numberFomated = !_.isNaN(amount) ? result : "";
        if(numberFomated !== "" && formatedNumber){
          numberFomated =  numberFomated.toLocaleString();
        }
          return (numberFomated === NaN)? "": numberFomated;

    } catch(e){

        if(formatedNumber){
          amount =  amount.toLocaleString();
        }
        return (amount=== NaN)? "": amount;
        
    }
};


export const fixAmountDecimals = (value, coinType = "", amountType = false) => {
    let num = Number(value); 
    if (isNaN(num)) return "";
    
    if(coinType === "fiat"){
      return Number(num.toFixed(2));
    }
 
    const decimalPlaces = num.toString().split(".")[1]?.length || 0;
    if (decimalPlaces > 8 && amountType) {

        return Number(num.toFixed(8));
    } else if (decimalPlaces > 4) {

        return Number(num.toFixed(4));
    } else if (decimalPlaces > 2) {

        return Number(num.toFixed(2));
    } else {

        return Number(num.toFixed(5));
    }

    return num;
}