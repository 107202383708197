import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logoIcon   from "../assets/logo.png";
import '../styles/HeroSection.css';

const ResetPasswordWelcomeStatement = () => {
  return (
    <section className="section-dark-text">
     <div className="logo-login hidden">
       <Link to="/">
        <img src={logoIcon} alt="peerpesa" className="logo-snap" />
       </Link>
     </div> 
     <h2 className="dark-title">
      Swift, <span className="dark-highlight">Reliable</span>, 
      and <span className="dark-highlight">Borderless</span>
      <span className="dark-highlight-out">Transactions for</span> 
      <span className="dark-highlight">Everyone</span>
     </h2> 
    </section>
  );
};

export default ResetPasswordWelcomeStatement;
