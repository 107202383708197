import React, { useState, useEffect, useE } from "react";
import Select from 'react-select';
import '../styles/TransferForm.css';
import sendIcon   from "../assets/other/send.svg";
import buyingIcon   from "../assets/plus.png";
import sellingIcon   from "../assets/minus.png";
import swappingIcon   from "../assets/swapping.svg";

import {getCurrencies, getCurrencyRate, getCurrenciesNetworks} from '../requests/requests'
import {fixAmountDecimals, formatAmount} from '../helpers/textFormat';

import exchangeIcon   from "../assets/other/exchange/exchange.svg";
import exchangeActiveIcon   from "../assets/other/exchange/exchange-active.svg";

import buyIcon   from "../assets/other/exchange/arrow-up.svg";
import buyActiveIcon   from "../assets/other/exchange/arrow-up-active.svg";
import sellIcon   from "../assets/other/exchange/arrow-down-active.svg";
import sellActiveIcon   from "../assets/other/exchange/arrow-down-active.svg";

//import { useNavigate } from "react-router-dom";

const TransferForm = () => {

  const [currencies, setCurrencies] = useState([]);
  const [currenciesNetworks, setCurrenciesNetworks] = useState([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [currencyRate, setCurriencyRate] = useState("");
  const [transferTime, setTransferTime] = useState(" Approx 1 min");
  const [transferFees, setTransferFees] = useState(3.2);
  const [transferFeeAmount, setTransferFeeAmount] = useState(0);
  
  
  const [consideredCurrencies, setConsideredCurrencies] = useState([]);
  const [consideredCurrenciesOther, setConsideredCurrenciesOther] = useState([]);

  const [consideredTopLabel, setConsideredTopLabel] = useState("");
  const [consideredOtherLabel, setConsideredOtherLabel] = useState("");

  const [buyCurrency, setBuyCurrency] = useState({});
  const [sellCurrency, setSellCurrency] = useState({});
  const [sellCurrencyAmount, setSellCurrencyAmount] = useState(0);
  const [buyCurrencyAmount, setBuyCurrencyAmount] = useState(0);
  const [formOpOption, setFormOpOption] = useState("buy");

  const initFormOptionFuc = async () => {
    if(formOpOption === 'swap'){

      setConsideredCurrencies(cryptoCurrencies);
      setConsideredCurrenciesOther(cryptoCurrencies);
      setBuyCurrency(cryptoCurrencies[cryptoCurrencies.length - 1]);
      setSellCurrency(cryptoCurrencies[0]);
      setConsideredTopLabel("Swap")
      setConsideredOtherLabel("Recipient gets")
      
     } else if(formOpOption === 'buy'){
      
      setConsideredCurrencies(fiatCurrencies);
      setConsideredCurrenciesOther(cryptoCurrencies);
      setSellCurrency(fiatCurrencies[fiatCurrencies?.length - 1]);
      setBuyCurrency(cryptoCurrencies[0]);
      setConsideredTopLabel("Spend")
      setConsideredOtherLabel("Recipient gets")
     } else if(formOpOption === 'sell'){
      
      setConsideredCurrencies(cryptoCurrencies);
      setConsideredCurrenciesOther(fiatCurrencies);
      setSellCurrency(cryptoCurrencies[cryptoCurrencies?.length - 1]);
      setBuyCurrency(fiatCurrencies[0]);
      setConsideredTopLabel("Send")
      setConsideredOtherLabel("Recipient gets")
     }
     
  }

  const initFuc = async () => {

    const currencyList = await getCurrencies();
    if(currencyList?.data.length > 0){
        
       currencyList?.data.map((item, key) => {
         item.icon = require(`../assets/flag/${item?.symbol?.toLowerCase()}.png`);
       });

       setCurrencies(currencyList?.data)
       let collectionFiat    = []
       let collectionCrypto  = []
       currencyList?.data.map((option, key) => {
            if(option.token_type === 'fiat'){
              collectionFiat.push(option);  
            }
            if(option.token_type !== 'fiat'){
              collectionCrypto.push(option);
            } 
       });

       setFiatCurrencies(collectionFiat);
       setCryptoCurrencies(collectionCrypto);

    }
  }

  useEffect(() => {
    initFuc();
    setFormOpOption("buy");
    initFormOptionFuc(); 
    initCoinNetworks(); 
    initNetworks();
  }, [])

  useEffect(() => {
    initFormOptionFuc(); 
  }, [formOpOption, currencies])

  useEffect(() => {
    initRates(); 
    initNetworks();
  }, [sellCurrency, formOpOption, buyCurrency])

  const initNetworks = async () => {
    try{
      // transfer time duration here
      let transferTime = currenciesNetworks.filter((option) => option.label === sellCurrency?.symbol)[0]?.duration
      transferTime =  transferTime ?? '3 mins';
      setTransferTime(`Approx ${transferTime}`);
    }catch(e){
      setTransferTime(`Approx 3 mins`);
    }
  }

  const initRates = async () => {

    let ratesResponse = await getCurrencyRate({base_coin: sellCurrency?.symbol,  quote_coin: buyCurrency?.symbol});
    let theRate = ratesResponse?.data?.exchange_rate?.marketcap_amount;
    if(formOpOption === 'sell'){
      theRate = ratesResponse?.data?.sell_send_rate?.marketcap_amount;
    } else if(formOpOption === 'buy'){
      theRate = ratesResponse?.data?.buy_rate?.marketcap_amount;
    } else {
      theRate = ratesResponse?.data?.swap_rate?.marketcap_amount;
    }

    if(!Number(theRate)){
      theRate = 0;
    }
    theRate = await fixAmountDecimals(theRate, buyCurrency?.token_type, false);
    setCurriencyRate(theRate);
  }

  const initCoinNetworks =  async () => {
    try{
      let currencyNetworksList = await getCurrenciesNetworks();
      if(currencyNetworksList?.data.length > 0){
        setCurrenciesNetworks(currencyNetworksList?.data);
      }
    } catch(e){}
  }

  //const navigate = useNavigate();
  const [currencyCollection, setCurrencyCollection] = useState({
                                                currencyOptions: [
                                                  { value: '1', label: 'Bank' },
                                                  { value: '2', label: 'Mobile Money' }
                                                ],
                                                buyCurrencyFilter: [],
                                                sellCurrencyFilter: []
                                             });    
  
  const sellingCurrencyChange = (e) => {
    setSellCurrency(e);
  } 

  const buyingCurrencyChange = (e) => {
    setBuyCurrency(e);
  } 

  const formOperationOption = async (e) => {
    setFormOpOption(e)
  } 

  // Custom option label with image and text
  const formatOptionLabel = ({ symbol, icon }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={icon} alt={symbol} className="icon-border-section" />
        <span>{symbol}</span>
      </div>
  );

  const swapSellBuyOptions = () => {
    if(formOpOption === 'sell'){
      setFormOpOption('buy')
    } else if(formOpOption === 'buy'){
      setFormOpOption('sell')
    }
  }

  // {/* <div className="rates-label-tabs hidden">
          //   <span className="exchanging normal-rating-bg hidden"><img src={exchangeIcon} alt="send" className="button-snap" /></span> 
          //   <span className="exchanging active-rating-bg "><img src={exchangeActiveIcon} alt="send" className="button-snap" /></span> 
          // </div>
          // <div className="rates-label-tabs hidden">
          //   <span className="buytab normal-rating-bg hidden"> <strong>Buy</strong> <img src={buyIcon} alt="send" className="button-snap" /></span> 
          //   <span className="buytab active-rating-bg hidden"> <strong>Buy</strong> <img src={buyActiveIcon} alt="send" className="button-snap" /></span>
          //   <span className="buytab normal-rating-bg hidden"> <strong>Sell</strong> <img src={sellIcon} alt="send" className="button-snap" /></span> 
          //   <span className="buytab active-rating-bg hidden"> <strong>Sell</strong> <img src={sellActiveIcon} alt="send" className="button-snap" /></span> 
  // </div> */}
    
  return (
    <div className="card">
      <form className="form">
        
        <div className="buy-currency-oprions">
          <div className="buy-details-label-tabs">
            <label className={(formOpOption === 'sell')? "buy-details buy-details-active":"buy-details" }
                  onClick={() => formOperationOption('sell')} >Send</label> 
            <label className={(formOpOption === 'buy')? "buy-details buy-details-active": "buy-details"}
                  onClick={() => formOperationOption('buy')}>Buy</label> 
          </div>

          <label className={(formOpOption === 'swap')? "last-btn active":"buy-send active-tab_"} 
                 onClick={() => formOperationOption('swap')}> 

              <img src={swappingIcon} className="swap-buy-icon" /> 
              <span className="text-center"> Swap</span> 
          </label>
        </div>

        <div className="buy-currency outer-blocking-buy">
          <div className="selection-currency-block clearfix group">
            <label> {consideredTopLabel} </label>
            <input className="column_1000_ input_" type="text" value={sellCurrencyAmount} 
              onChange={(e) => {
              let totalAmount = e.target.value;
              if(totalAmount !=="" && !Number(totalAmount)){
                  return;
              }
              setSellCurrencyAmount(totalAmount)
              let transferFee = ((transferFees/100)*totalAmount)
              transferFee = (Number(transferFee) && !isNaN(transferFee))? transferFee:""
              setTransferFeeAmount(transferFee);
              
              if(currencyRate > 0){
                let sellAmt = (currencyRate * totalAmount);
                setBuyCurrencyAmount(sellAmt) 
              } 

            }}/>
          </div>  
          <Select
                  classNamePrefix="custom-select"
			           	className="selection"
                  value={sellCurrency}
                  options={consideredCurrencies}
                  formatOptionLabel={formatOptionLabel}
                  placeholder=""
                  onChange={(selectedOption) => sellingCurrencyChange(selectedOption)}
                />
        </div>

        <div className="sell-currency-rating flexing_content flex_container container-rating">
          <div className="rates-label">
            <p className="text-center"> <b>Estimated rate : </b> 
                                        1 <b>{sellCurrency?.symbol} </b> 
                                         ~ {formatAmount(currencyRate, true)} 
                                         <b> {buyCurrency?.symbol}</b> </p>
          </div>

          <div className={(formOpOption === 'sell' || formOpOption === 'buy')? "rates-label-tabs":"hidden"} onClick={() => swapSellBuyOptions()}>
            <span className={(formOpOption === 'sell')? "exchanging normal-rating-bg":"hidden" }><img src={exchangeIcon} alt="send" className="button-snap" /></span> 
            <span className={(formOpOption === 'buy')? "exchanging active-rating-bg": "hidden"}><img src={exchangeActiveIcon} alt="send" className="button-snap" /></span> 
          </div>

        </div>

        <div className="sell-currency outer-blocking-buy">
          <div className="selection-currency-block clearfix group">
              <label>{consideredOtherLabel}</label>
              <input className="column_1000_ input_"  type="text" value={buyCurrencyAmount} 
                    onChange={(e) => {
                          let totalAmount = e.target.value;
                          if(totalAmount !=="" && !Number(totalAmount)){
                              return;
                          }
                          setBuyCurrencyAmount(totalAmount)
                          if(currencyRate > 0){
                            let sellAmt = ( totalAmount/currencyRate);
                            setSellCurrencyAmount(sellAmt) 
                          } 
                    }}/>
          </div>       
          <Select
                classNamePrefix="custom-select"
			        	className="selection"
                value={buyCurrency}
                options={consideredCurrenciesOther}
                formatOptionLabel={formatOptionLabel} 
                onChange={(selectedOption) => buyingCurrencyChange(selectedOption)}
                placeholder=""
              />
        </div>      

        <div className="form-inputs" style={{maxWidth: '100%'}}>
          <label> Mode of payment </label>
          <div className="clearfix group selection-payment-method-front">
            <Select
                   value={currencyCollection.currencyOptions[2]}
                   placeholder="select payment method"
				           className="custom-select-one selection-other"
                   classNamePrefix="custom-select"
                   options={currencyCollection.currencyOptions}
				           isSearchable="true" />

          </div>
        </div>
        <div className="payment-details">
          <p  className="group clearfix"> 
              <b>Sell Amount</b><span className="float-right">{formatAmount(sellCurrencyAmount ?? "", true)} {sellCurrency?.symbol??''}</span></p>
          <p  className="group clearfix">
              <b>Network Fee</b><span className="float-right">{formatAmount(transferFeeAmount ?? "", true)} {sellCurrency?.symbol ?? ''}</span></p>
          <p  className="group clearfix"> 
              <b>Transfer Time</b> <span className="float-right">{transferTime ?? ''}</span></p>
          <p  className="group clearfix"> 
              <b>Amount</b> <span className="float-right">{formatAmount(buyCurrencyAmount ?? "", true)} {buyCurrency?.symbol??''}</span></p>
          <p  className="group clearfix"> 
              <b>Total Amount</b> <span className="float-right">{formatAmount(buyCurrencyAmount ?? "", true)} {buyCurrency?.symbol??''}</span></p>
        </div>
        <center>
          <button className="send-button">
            <img src={sendIcon} alt="send" className="button-snap" />
            <span>Send Money</span>
          </button>
        </center>
      </form>
    </div>
  );
};

export default TransferForm;
