import { postRequest, deleteRequest, putRequest, getRequest } from './api/api';
export const registerAccount = async (payload) => {
  try {
    const response = await postRequest('/auth/register', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const loginAccount = async (payload) => {
  try {
    const response = await postRequest('/auth/login', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const passwordTokenRequest = async (payload) => {
  try {
    const response = await postRequest('/auth/password/reset/token', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    //console.log('Error 1', error);
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const tokenRequest = async (payload) => {
  try {
    const response = await postRequest('/auth/token', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    //console.log('Error 1', error);
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const resetPasswordRequest = async (payload) => {
  try {
    const response = await postRequest('/auth/reset/password', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const verifyTokenRequest = async (payload) => {
  try {
    const response = await postRequest('/auth/token/verification', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};


export const profileDetails = async (payload = {}) => {
  try {
    const response = await getRequest('/profile', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};


export const getAccountWallets = async (payload = {}) => {
  try {
    const response = await getRequest('/wallet/addresses', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const rejenerateJWT = async (payload) => {
  try {
    const response = await postRequest('/jwt/reverification', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};



export const getCurrencies = async (payload = {}) => {
  try {
    const response = await getRequest('/currencies', payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const getCurrenciesByQuotes = async (payload = {}) => {
  try {
    const response = await getRequest(`/currencies/quote/${payload.quote_coin}`, payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const getSingleCurrencies = async (payload = {}) => {
  try {
    const response = await getRequest(`/currencies/${payload.currency}`, payload);
    //console.log('POST response:', response);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const getCurrenciesNetworks = async (payload = {}) => {
  try {
    const response = await getRequest(`/currencies/networks/list`, payload);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const getCurrencyRate = async (payload = {}) => {
  try {
    const response = await getRequest(`/rates/${payload.base_coin}/${payload.quote_coin}`, payload);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};


export const getKycAccessToken = async (payload) => {
  try {

    const response = await postRequest('/kyc/user', payload);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};

export const getCountirsList = async (payload) => {
  try {
    const response = await getRequest('/settings/countries', payload);
    return response;
  } catch (error) {
    return {'status': 'error', 'message': error?.message, 'data': error};
  }
};






// export const handleDelete = async (payload) => {
//   try {
//     const response = await deleteRequest('/resource/123');
//     console.log('DELETE response:', response);
//     setData(response);
//   } catch (error) {
//     console.error('DELETE error:', error);
//   }
// };
// export const handlePut = async (payload) => {
//   try {
//     const payload = { name: 'Updated Sample', value: 84 };
//     const response = await putRequest('/resource/123', payload);
//     console.log('PUT response:', response);
//     setData(response);
//   } catch (error) {
//     console.error('PUT error:', error);
//   }
//};
