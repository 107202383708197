import React, { useEffect } from "react";
import Cookies from "js-cookie";


const getDataStorage = async (theKey, _boolean, _isJson) => {
    
    const dataValue = await Cookies.get(theKey);
    if (!dataValue) return false; 
    if (!_boolean) {
        return _isJson ? JSON.parse(dataValue) : dataValue;
    }
    return true; 
};

const setDataStorage = async (theKey, theData, json = false) => {

    theData = (json)? JSON.stringify(theData): theData;
    await Cookies.set(theKey, theData, { expires: 1 });
};


const removeDataStorage = async (theKey) => {
    Cookies.remove(theKey)
}

export { getDataStorage, setDataStorage, removeDataStorage };
