import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useCounter } from '../hooks/useCounter';
import { useAuth } from '../hooks/useAuth';

import { getDataStorage } from "../helpers/storage";

import '../styles/Header.css';
import { FaCoins }     from 'react-icons/fa';
import logoIcon        from "../assets/logo.png";
import logoSmallIcon   from "../assets/small-logo.png";
import walletIcon      from "../assets/other/walletx.svg";
import menuIcon        from "../assets/other/menu.svg";
import userIcon        from "../assets/other/user.svg";
import loginArrowIcon  from "../assets/other/login-arrow.svg";
import AccountDetails from './AccountDetails';

const Header = () => {
  
  const { value, increment, decrement, incrementByAmount } = useCounter();
  const { theme, toggleTheme } = useAuth();

  const [mobileMenu, setMobileMenu] = useState(false);
  const [userMenu,   setUserMenu] = useState(false);
  const [accountAuth,   setAccountAuth] = useState(false);
  const navigate = useNavigate();
  const goTo = (route) => {
    navigate(route); // Navigate to the About page
  };
  const [scrollYPosition, setScrollYPosition] = React.useState(0);
  
  const handleScroll = () => {
    setUserMenu(false)
    setMobileMenu(false)
  };

  useEffect(() => {

    const checkAuth = async () => {
      const authToken = await getDataStorage("authToken", true, false);
      setAccountAuth(authToken);
    };
    checkAuth();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
    };


  }, []);

  const showAccountDetailsTab = () => {
    setMobileMenu(false);
    setUserMenu(!userMenu)
  }

  const showMobileMenuDetailsTab = () => {
     setUserMenu(false)
     setMobileMenu(!mobileMenu)
  }

  return (
    <nav className="nav">
      <div className="logo">
        <Link to="/">
          <img src={logoIcon} alt="peerpesa" className="logo-snap show-big-screen" />
          <img src={logoSmallIcon} alt="peerpesa" className="logo-snap show-small-screen" />
        </Link>   
      </div>


      <div className={(mobileMenu)? "nav-links":"nav-links  hide_show_menu"}>
       
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/about" className="nav-link">About</Link>
          <Link to="https://pay.peerpesa.co" className="nav-link">dApp</Link>
          {
            !accountAuth && (
              <>
                <Link to="/login" className="nav-link">Log In</Link>
                <Link to="/register" className="nav-link">Sign Up</Link>
              </>
            )
          }
          

          <Link to="/support" className="nav-link">Support</Link>

        </div>

        <div  className="side-menu-section flexing_content flex_container">
        
          <button className="btn  btn-small-extende-right buy-on-mobile-view" onClick={(e) => goTo('/buy?coin=')}>
            <img src={walletIcon} alt="buy" className="buy-snap buy-snap-hide-smallx" />  
            Buy Crypto
          </button>
          
          {/* if the user is not logged in. then login and register  */}
          <button className={!accountAuth? "btn  btn-small-extende-right login_btn mobile_login_hide": "hidden"} onClick={(e) => goTo('/login')}>
            Log In
            <img src={loginArrowIcon} alt="buy" className="login-snap buy-snap-hide-small" />  
          </button>

          <button className={!accountAuth? "btn  btn-small-extende-right signup_btn mobile_login_hide": "hidden"} onClick={(e) => goTo('/register')}>
            Sign Up
          </button>


          {/*if the user is logged in  */}
          {(accountAuth)? 
            <div className="display-inline">
              <button className="btn-non-bg"  onClick={(e) => showAccountDetailsTab()}>
                <img src={userIcon} alt="menu" className="menu-snap" />  
              </button>
              <AccountDetails showHide={userMenu} /> 
            </div> : "" } 

            <button className="btn-non-bg hide-menu-tab menu-on-mobile-view"  onClick={(e) => showMobileMenuDetailsTab()}>
              <img src={menuIcon} alt="menu" className="menu-snap" />  
            </button>
        
       </div>  
    </nav>
  );
};

export default Header;
