import React, {useEffect, useState} from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import LoggedBanner from '../components/LoggedBanner';
import useWallets from '../hooks/useWallets';
import {DynamicImage} from '../helpers/dynamicSnap';

import { useNavigate, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

function Wallets() {

  const { accountWallets, systemWallets, isLoadingWallets } = useWallets();
  const [userWallets, setUserWallets] = useState([])

  const navigate = useNavigate();
  const navigating = (route) => {
    navigate(route); // Navigate to the About page
  }

  useEffect(() => {
    sortingCurrencyObjs();
  },[]);

  useEffect(() => {
    sortingCurrencyObjs();
  },[accountWallets]);

  const sortingCurrencyObjs  = () => {
    accountWallets.sort((a, b) => {
      if (a.token_type === 'fiat' && b.token_type !== 'fiat') {
        return 1; 
      } else if (a.token_type !== 'fiat' && b.token_type === 'fiat') {
        return -1;
      }
      return 0;
    });
    setUserWallets(accountWallets);
  }
    
  return (
    <div className="App">
      
      <Header />
      <LoggedBanner />

      
      <div className="logged-dashboard-width flexing_content flex_container nopaddding-small">
        <div className="main-dashboard-section column_100 margin-bottom-30"> 
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">

              <h4 className="title white-text text-left">
                 My Assets
              </h4>
              <Table className="the-table-content">
                  <Thead>
                    <Tr>
                      <Th>Currency</Th>
                      <Th>Amount</Th>
                      <Th>Avialable</Th>
                      <Th>Wallet</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      (userWallets).map((wallet, theKey) => (
                        <Tr key={theKey}>
                            <Td>
                              <div class="icon">
                                <img  src={DynamicImage({imageName: wallet.symbol?.toLowerCase()})} alt={wallet.token_name} />
                                <span>{wallet.token_name}</span>
                              </div>
                            </Td>
                            <Td>{(wallet?.wallet?.balance)? wallet?.wallet?.balance : 0}</Td>
                            <Td>{(wallet?.wallet?.balance)? wallet?.wallet?.balance : 0}</Td>
                            <Td>{(wallet?.wallet?.address !== undefined && wallet?.wallet?.address !== "")? wallet?.wallet?.address : ""}</Td>
                            <Td  className="text-right">
                              <span className="button margin-left-bottom-10-1">Swap</span>
                              <span className="button margin-left-bottom-10-1">Send</span>
                              <span className="button margin-left-bottom-10-1">Deposit</span>
                            </Td>
                         </Tr> 
                      ))
                    }

                  </Tbody>
                </Table>
            </div>
          </div> 
        </div>
      </div>

    </div>
  );
}

export default Wallets;
