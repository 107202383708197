import React from 'react';
import { Box, Container, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  maxWidth: '440px !important',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(4),
  textAlign: 'center',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
}));

const SuccessIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  fontSize: '64px',
  color: '#00C853',
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  borderRadius: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'none',
}));

const DetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 200, 83, 0.05)',
  borderRadius: '8px',
  padding: theme.spacing(2),
  margin: theme.spacing(3, 0),
}));

const TransactionSuccess = () => {
  const navigate = useNavigate();
  
  // This could come from route state or context
  const transactionDetails = {
    type: 'Purchase', // or 'Sale' or 'Swap'
    amount: '799.00 USDT',
    reference: 'TRX-123456789',
    date: new Date().toLocaleString(),
  };

  const handleViewTransactions = () => {
    navigate('/transactions');
  };

  const handleBackToHome = () => {
    navigate('/dashboard');
  };

  return (
    <div className="App">
      <Header />

      <StyledContainer>
        <StyledPaper>
          <SuccessIcon />
          
          <Typography variant="h6" sx={{ 
            color: '#000000', 
            fontWeight: 600,
            mb: 1,
          }}>
            Transaction Successful
          </Typography>

          <Typography variant="body2" sx={{ 
            color: '#000000',
            mb: 2,
          }}>
            Your {transactionDetails.type.toLowerCase()} has been completed successfully
          </Typography>

          <DetailBox>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              mb: 1,
            }}>
              <Typography variant="body2" sx={{ color: '#000000' }}>
                Amount
              </Typography>
              <Typography variant="body2" sx={{ 
                color: '#000000',
                fontWeight: 600,
              }}>
                {transactionDetails.amount}
              </Typography>
            </Box>

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
            }}>
              <Typography variant="body2" sx={{ color: '#000000' }}>
                Reference
              </Typography>
              <Typography variant="body2" sx={{ 
                color: '#000000',
                fontWeight: 500,
              }}>
                {transactionDetails.reference}
              </Typography>
            </Box>
          </DetailBox>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 1.5,
          }}>
            <StyledButton
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#000000',
                '&:hover': { backgroundColor: '#1a1a1a' },
              }}
              onClick={handleViewTransactions}
            >
              View Transactions
            </StyledButton>

            <StyledButton
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#000000',
                color: '#000000',
                '&:hover': {
                  borderColor: '#000000',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
              onClick={handleBackToHome}
            >
              Back to Home
            </StyledButton>
          </Box>

          <Typography 
            variant="caption" 
            sx={{ 
              color: '#00C853',
              display: 'block',
              mt: 2,
            }}
          >
            A confirmation email has been sent to your registered email address
          </Typography>
        </StyledPaper>
      </StyledContainer>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default TransactionSuccess; 