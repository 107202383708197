import React from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import LoginSection from '../components/LoginSection';


function Login() {
  return (
    <div className="App">
      <Header />
      <LoginSection />
    </div>
  );
}

export default Login;
