import React from 'react';
import '../styles/HeroSection.css';
import LoginTransferForm from './LoginTransferForm';
import LoginWelcomeStatement from './LoginWelcomeStatement';
const LoginSection = () => {
  return (
    <>      
    <section className="dark-section flexing_content flex_container dark-section-login margin-top-20">
      <div className="column_60">
        <LoginWelcomeStatement />
      </div>
      <div className="column_40 nopadding">
        <LoginTransferForm />
      </div>
    </section>
      
   </> 
  );
};

export default LoginSection;
