import React, { createContext, useState, useEffect, useReducer } from 'react';
import Cookies from "js-cookie";
export const AuthContext = createContext();
import { useMutation, useQuery } from "@tanstack/react-query";
import {dataPolling} from '../helpers/dataPolling'
import {profileDetails} from '../requests/requests'

const AuthProvider = ({ children }) => {

  const [theme, setTheme] = useState('light');
  const [authToken, setAuthToken] = useState("");
  const [authUser, setAuthUser] = useState("");
  const [refreshToken, setRefreshToken] = useState({});
 	const [isLoggedToken, setIsLoggedToken] = useState(false);
	const [authTokenLoading, setAuthTokenLoading] = useState(false);
  const [authState, authDispatch] = useReducer(authReducer, {
                                                              authToken: "",
                                                              refreshToken: "",
                                                              userDetails: {},
                                                              isLoadingAuth: false
                                                            });

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  // Fetch auth details query
  const getAuthDetails = useQuery({
    queryKey: ["userAuth"],
    queryFn: () => {
      walletsDispatch({ type: "fetchingWallets" });
      return profileDetails({ token: authTokens.IdToken });
    },
    enabled: false,
  });

  const verifyToken = useMutation({
		mutationFn: ({ token, loadPage }) => {
    	(loadPage)? setAuthTokenLoading(true) : setAuthTokenLoading(false)
			return profileDetails({ token });
		},
		onSuccess: (data, variables, context) => {
			setAuthTokenLoading(false)
		},
		onError: (error, variables, context) => {
		  // if(error.response?.status == 401){
			// 	setIsLoggedIn(false);
			// }
			setAuthTokenLoading(false)
		},
	});

  const checkAuthToken = () => {
    return Cookies.get("authToken");
  }

  const checkRefreshToken = () => {
    return Cookies.get("refreshToken");
  }

  const checkAuthUser = () => {
    return Cookies.get("loggedUser");
  }

  const refreshAuthToken = () => {
    let _authToken = checkAuthToken();
		if (_authToken !== "" && _authToken != null) {
			try {
        setAuthToken(_authToken);
        const lUser  = checkAuthUser();
				const _authUser = (lUser !== undefined && lUser !== "")? JSON.parse(lUser):{};
        setAuthUser(_authUser);
        const refreshTokenx  = checkRefreshToken();
        setRefreshToken(refreshTokenx);
        verifyToken.mutate({ token: _authToken , loadPage: true });			
      } catch (error) { }
		}
  }

  const TOKEN_VALIDITY_CHECK_INTERVAL = 30 * 1000;
  dataPolling(refreshAuthToken, TOKEN_VALIDITY_CHECK_INTERVAL)

	useEffect(() => {
    refreshAuthToken();
	}, []);

  return (
    <AuthContext.Provider 
      value={{  ...authState,
                theme, 
                toggleTheme }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;

// Wallets Reducer
const authReducer = (state, action) => {
  switch (action.type) {
    case "reload":
      return { ...state, auth: action?.authToken, isLoadingAuth: false };
    case "refresh":
      return { ...state, isLoadingAuth: true, auth: action?.authToken, refresh: action?.refreshToken, };
    default:
      throw new Error(`[authReducer] Unknown action type: ${action.type}`);
    }
};
