import React, {useEffect, useState} from 'react';
import {DynamicImage} from '../helpers/dynamicSnap';
import '../styles/MainSection.css';
import '../styles/Assets.css';
import Header from '../components/Header';
import LoggedBanner from '../components/LoggedBanner';
import useCurrency from '../hooks/useCurrency';
import {fixAmountDecimals, formatAmount} from '../helpers/textFormat';

import { useNavigate, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


function Assets() {

  const { accountCurrency, systemCurrency, isLoadingCurrency } = useCurrency();
  const currencyObj = useCurrency();
  const [userCurrency, setUserCurrency] = useState([])
  const [filterType, setFilterType] = useState('all');

  const navigate = useNavigate();
  const navigating = (route) => {
    navigate(route); // Navigate to the About page
  }

  useEffect(() => {
    sortingCurrencyObjs();
  },[]);

  useEffect(() => {
    sortingCurrencyObjs();
  },[accountCurrency]);

  const sortingCurrencyObjs  = () => {
    accountCurrency.sort((a, b) => {
      if (a.token_type === 'fiat' && b.token_type !== 'fiat') {
        return 1; 
      } else if (a.token_type !== 'fiat' && b.token_type === 'fiat') {
        return -1;
      }
      return 0;
    });
    setUserCurrency(accountCurrency);
  }

  const filteredCurrencies = userCurrency.filter((currency) => {
    if (filterType === 'fiat') {
      return currency.token_type === 'fiat';
    } else if (filterType === 'coin') {
      return currency.token_type !== 'fiat';
    }
    return true; // 'all' shows everything
  });
    
  return (
    <div className="App">
      
      <Header />

      <div className="logged-dashboard-width flexing_content flex_container nopaddding-small">
        <div className="main-dashboard-section column_100 margin-bottom-30"> 
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">

              <h4 className="title white-text text-left">
                 My Assets
              </h4>
              <div className="filter-container">
                <label className="filter-label" htmlFor="balanceFilter">
                  Filter:
                </label>
                <select
                  id="balanceFilter"
                  className="filter-select"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                >
                  <option value="all">All Balances</option>
                  <option value="fiat">Fiat Balances</option>
                  <option value="coin">Coin Balances</option>
                </select>
              </div>
              <Table className="the-table-content">
                <Thead>
                  <Tr>
                    <Th>Asset</Th>
                    <Th>Coin Price / Cost Price</Th>
                    <Th>Today's Volume</Th>
                    <Th>Today's PNL</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredCurrencies.map((currency, theKey) => (
                    <Tr key={theKey} className="table-row">
                      <Td>
                        <div className="icon">
                          <img src={DynamicImage({ imageName: currency.symbol?.toLowerCase() })} alt={currency.token_name} />
                          <span>{currency.token_name}</span>
                        </div>
                      </Td>
                      <Td>{(currency?.price?.amount) ? `1 ${currency?.price?.base_coin} / ${fixAmountDecimals(currency?.price?.amount, currency?.price?.quote_coin, false)} ${currency?.price?.quote_coin}` : 0}</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td className="text-right">
                        <div className="button-container">
                          <span className="button" style={{ backgroundColor: "#39B662" }}>Buy</span>
                          <span className="button" style={{ backgroundColor: "#007BFF" }}>Swap</span>
                          <span className="button" style={{ backgroundColor: "#C01D2A" }}>Send</span>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div> 
        </div>
      </div>

    </div>
  );
}

export default Assets;
