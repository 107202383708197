import React, { createContext, useState, useEffect, useReducer } from "react";
import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import { getAccountWallets } from "../requests/requests";

export const WalletsContext = createContext();

// Reducer for managing wallets state
const walletsReducer = (state, action) => {
  switch (action.type) {
    case "reload":
      return { 
        ...state, 
        accountWallets: action.payload, 
        isLoadingWallets: false 
      };
    case "refresh":
      return {
        ...state,
        accountWallets: action.payload,
        isLoadingWallets: false
      };
    case "setWallets":  // New case to update accountWallets
      return {
        ...state,
        accountWallets: action.payload,
        isLoadingWallets: false
      };
    default:
      throw new Error(`[walletsReducer] Unknown action type: ${action.type}`);
  }
};

const WalletsProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [walletsTokenLoading, setWalletsTokenLoading] = useState(false);
  const [walletsState, walletsDispatch] = useReducer(walletsReducer, {
    accountWallets: [],
    systemWallets: [],
    isLoadingWallets: false,
  });

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  const walletsFetch = useMutation({
    mutationFn: ({ authToken, loadPage }) => {
      setWalletsTokenLoading(loadPage);
      return getAccountWallets({ authToken });
    },
    onSuccess: (data) => {
      setWalletsTokenLoading(false);
      if (data.status) {
        walletsDispatch({ type: "setWallets", payload: data.wallets || [] });
      }
    },
    onError: () => setWalletsTokenLoading(false),
  });

  const checkAccountWallets = () => Cookies.get("authToken");

  const refreshWalletsToken = () => {
    const _authToken = checkAccountWallets();
    if (_authToken) {
       walletsFetch.mutate({ authToken: _authToken, loadPage: true });
    }
  };

  useEffect(() => {
    refreshWalletsToken();
  }, []);

  const updateWallets = async () => {
    await refreshWalletsToken();
  };

  return (
    <WalletsContext.Provider
      value={{
        ...walletsState,
        theme,
        toggleTheme,
        updateWallets,
      }}
    >
      {children}
    </WalletsContext.Provider>
  );
};

export default WalletsProvider;
