import React, { createContext, useState, useEffect, useReducer } from "react";
import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import { getCurrencies } from "../requests/requests";

export const CurrencyContext = createContext();

// Reducer for managing currency state
const currencyReducer = (state, action) => {
  switch (action.type) {
    case "reload":
      return { 
        ...state, 
        appCurrency: action.payload, 
        isLoadingCurrency: false 
      };
    case "refresh":
      return {
        ...state,
        accountCurrency: action.payload,
        isLoadingCurrency: false
      };
    case "setCurrency":  // New case to update accountWallets
      return {
        ...state,
        accountCurrency: action.payload,
        isLoadingCurrency: false
      };
    default:
      throw new Error(`[currencyReducer] Unknown action type: ${action.type}`);
  }
};

const CurrencyProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [currencyTokenLoading, setCurrencyTokenLoading] = useState(false);
  const [currencyState, currencyDispatch] = useReducer(currencyReducer, {
    accountCurrency: [],
    systemCurrency: [],
    isLoadingCurrency: false,
  });
  
  const currencyFetch = useMutation({
    mutationFn: ({ authToken, loadPage }) => {
      setCurrencyTokenLoading(loadPage);
      return getCurrencies({ authToken });
    },
    onSuccess: (data) => {
      setCurrencyTokenLoading(false);
      if (data.status) {
        currencyDispatch({ type: "setCurrency", payload: data.data || [] });
      }
    },
    onError: () => setCurrencyTokenLoading(false),
  });


  const checkAccountCurrency = () => Cookies.get("authToken");
  const refreshCurrencyToken = () => {
    const _authToken = checkAccountCurrency();
    if (_authToken) {
       currencyFetch.mutate({ authToken: _authToken, loadPage: true });
    }
  };

  useEffect(() => {
    refreshCurrencyToken();
  }, []);

  const updateCurrency = async () => {
    await refreshCurrencyToken();
  };

  return (
    <CurrencyContext.Provider
      value={{
        ...currencyState,
        theme,
        updateCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
