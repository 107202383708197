import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai'; // Example icon from react-icons
import '../../styles/verificationBanner.css'
const IdentityVerification = () => {
  const navigate = useNavigate();

  const goTo = (route) => {
    navigate(route);
  };

  return (
    <div className="verification-banner">
      <div className="verification-content">
        {/* Icon - optional */}
        <AiOutlineCheckCircle className="verification-icon" />

        <div className="verification-text">
          <h3>Verify your account</h3>
          <p>
            Complete your account verification to access all features 
            and keep it secure.
          </p>
        </div>
      </div>

      <button
        className="verification-button"
        onClick={() => goTo('/kyc/verification')}
      >
        Get Verified
      </button>
    </div>
  );
};

export default IdentityVerification;
