import React from 'react';
import { Box, Container, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  maxWidth: '500px !important',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '0.875rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.875rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px',
  borderRadius: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const ConfirmWithdraw = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cryptoAmount, cryptoType, country, paymentMethod, localAmount } = location.state || {};

  const handleConfirm = () => {
    navigate('/transaction-success', {
      state: {
        type: 'Withdrawal',
        amount: `${cryptoAmount} ${cryptoType}`,
        localAmount: `${localAmount} ${country}`,
        reference: `WD${Math.random().toString(36).substr(2, 9).toUpperCase()}`,
      }
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="App">
        <Header />
        <StyledContainer>
        <StyledPaper>
            <Typography variant="h6" sx={{ color: '#000000', fontWeight: 600, mb: 1 }}>
            Confirm Withdrawal
            </Typography>
            <Typography variant="body2" sx={{ color: '#00C853', mb: 3 }}>
            Transaction Details
            </Typography>

            <Box sx={{ mb: 3 }}>
            <DetailRow>
                <DetailLabel>Amount to Withdraw</DetailLabel>
                <DetailValue>{cryptoAmount} {cryptoType}</DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>You Will Receive</DetailLabel>
                <DetailValue sx={{ color: '#00C853', fontWeight: 600 }}>
                {localAmount} {country}
                </DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>Country</DetailLabel>
                <DetailValue>{country}</DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>Payment Method</DetailLabel>
                <DetailValue>{paymentMethod === 'mobile_money' ? 'Mobile Money' : 'Bank Transfer'}</DetailValue>
            </DetailRow>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <StyledButton
                fullWidth
                variant="contained"
                sx={{
                backgroundColor: '#000000',
                '&:hover': { backgroundColor: '#1a1a1a' },
                }}
                onClick={handleConfirm}
            >
                Confirm Withdrawal
            </StyledButton>

            <StyledButton
                fullWidth
                variant="outlined"
                sx={{
                borderColor: '#000000',
                color: '#000000',
                '&:hover': {
                    borderColor: '#000000',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                }}
                onClick={handleBack}
            >
                Back
            </StyledButton>
            </Box>

            <Typography 
            align="center" 
            sx={{ 
                color: '#00C853', 
                mt: 2,
                fontSize: '0.875rem',
            }}
            >
            Step 2/3
            </Typography>

            <Typography 
            variant="caption" 
            sx={{ 
                color: '#000000', 
                display: 'block', 
                textAlign: 'center',
                mt: 2,
            }}
            >
            Please ensure all details are correct before confirming.
            Withdrawal processing time may vary based on the payment method.
            </Typography>
        </StyledPaper>
        </StyledContainer>

        <div>
            <Footer />
        </div>
    </div>
  );
};

export default ConfirmWithdraw; 