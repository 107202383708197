import { useContext } from "react";
import { WalletsContext } from "../context/WalletsContext";

const useWallets = () => {
  const context = useContext(WalletsContext);
  if (!context) {
    throw new Error("useWallets must be used within a WalletsProvider");
  }
  return context;
};

export default useWallets;
