import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Grid, Select, MenuItem, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  maxWidth: '600px !important',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: '#00C853',
  marginBottom: theme.spacing(1),
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '8px',
  '& .MuiSelect-icon': {
    color: '#657792',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
    '&:hover fieldset': {
      borderColor: '#B0B0B0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000000',
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
    '&:hover fieldset': {
      borderColor: '#B0B0B0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000000',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px',
  borderRadius: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const ConversionBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 200, 83, 0.05)',
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Withdraw = () => {
  const navigate = useNavigate();
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [cryptoType, setCryptoType] = useState('USDT');
  const [country, setCountry] = useState('UGX');
  const [paymentMethod, setPaymentMethod] = useState('mobile_money');

  // Mock exchange rates (you'll need to fetch real rates from your API)
  const exchangeRates = {
    'USDT': {
      UGX: 3800,
      KES: 120,
    }
  };

  const cryptoOptions = [
    { value: 'USDT', label: 'USDT', balance: '1,234.56' },
    { value: 'BTC', label: 'Bitcoin (BTC)', balance: '0.0234' },
    { value: 'ETH', label: 'Ethereum (ETH)', balance: '1.567' },
  ];

  const countries = [
    { value: 'UGX', label: 'Uganda (UGX)', flag: '🇺🇬' },
    { value: 'KES', label: 'Kenya (KES)', flag: '🇰🇪' },
  ];

  const paymentMethods = [
    { value: 'mobile_money', label: 'Mobile Money' },
    { value: 'bank', label: 'Bank Transfer' },
  ];

  const calculateLocalAmount = () => {
    if (!cryptoAmount) return '0';
    return (cryptoAmount * exchangeRates[cryptoType][country]).toFixed(2);
  };

  const handleSubmit = () => {
    navigate('/confirm-withdraw', {
      state: {
        cryptoAmount,
        cryptoType,
        country,
        paymentMethod,
        localAmount: calculateLocalAmount(),
      }
    });
  };

  return (
    <div className="App">
        <Header />
        <StyledContainer>
        <StyledBox>
            <Typography variant="h6" sx={{ color: '#000000', fontWeight: 600, mb: 1 }}>
            Withdraw Funds
            </Typography>
            <Typography variant="body2" sx={{ color: '#000000', mb: 3 }}>
            Convert your crypto to local currency
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* First Row */}
            <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
                gap: 2 
            }}>
                <Box>
                <InputLabel>Select Crypto Asset</InputLabel>
                <FormControl fullWidth>
                    <StyledSelect
                    value={cryptoType}
                    onChange={(e) => setCryptoType(e.target.value)}
                    >
                    {cryptoOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label} ({option.balance})
                        </MenuItem>
                    ))}
                    </StyledSelect>
                </FormControl>
                </Box>

                <Box>
                <InputLabel>Enter Amount</InputLabel>
                <StyledTextField
                    fullWidth
                    type="number"
                    placeholder={`Amount in ${cryptoType}`}
                    value={cryptoAmount}
                    onChange={(e) => setCryptoAmount(e.target.value)}
                />
                </Box>
            </Box>

            {/* Second Row */}
            <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
                gap: 2 
            }}>
                <Box>
                <InputLabel>Select Country</InputLabel>
                <FormControl fullWidth>
                    <StyledSelect
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    >
                    {countries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.flag} {option.label}
                        </MenuItem>
                    ))}
                    </StyledSelect>
                </FormControl>
                </Box>

                <Box>
                <InputLabel>Payment Method</InputLabel>
                <FormControl fullWidth>
                    <StyledSelect
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                    {paymentMethods.map((method) => (
                        <MenuItem key={method.value} value={method.value}>
                        {method.label}
                        </MenuItem>
                    ))}
                    </StyledSelect>
                </FormControl>
                </Box>
            </Box>

            {cryptoAmount && (
                <ConversionBox>
                <Typography variant="subtitle2" sx={{ color: '#000000' }}>
                    You will receive approximately:
                </Typography>
                <Typography variant="h5" sx={{ color: '#00C853', fontWeight: 600, my: 1 }}>
                    {calculateLocalAmount()} {country}
                </Typography>
                <Typography variant="caption" sx={{ color: '#000000' }}>
                    Exchange rate: 1 {cryptoType} = {exchangeRates[cryptoType][country]} {country}
                </Typography>
                </ConversionBox>
            )}

            <StyledButton
                fullWidth
                variant="contained"
                sx={{
                backgroundColor: '#000000',
                '&:hover': { backgroundColor: '#1a1a1a' },
                }}
                disabled={!cryptoAmount || !paymentMethod}
                onClick={handleSubmit}
            >
                Continue
            </StyledButton>

            <Typography 
                variant="caption" 
                sx={{ 
                color: '#000000', 
                textAlign: 'center',
                display: 'block',
                }}
            >
                Step 1/3
            </Typography>
            </Box>
        </StyledBox>
        </StyledContainer>

        <div>
            <Footer />
        </div>
    </div>
  );
};

export default Withdraw;
