import React, {useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../styles/MainSection.css';

import depositIcon   from "../assets/other/dashboard/deposit.svg";
import withdrawIcon  from "../assets/other/dashboard/withdraw.svg";
import transferIcon  from "../assets/other/dashboard/transfer.svg";
import sendIcon      from "../assets/other/dashboard/send.svg";

import Header from '../components/Header';
function Transactions() {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [transType, setTransType] = useState("")
  const navigate = useNavigate();
  const navigating = (route) => {
    navigate(route); // Navigate to the About page
  }

  return (
    <div className="App">

      <Header />

      <div className="logged-dashboard-width flexing_content flex_container nopaddding-small">
        <div className="main-dashboard-section column_100 margin-bottom-30">
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">
              
              <h4 className="title white-text text-left">
                Transactions
              </h4>

              <div className="group clearfix flexing_content flex_container content-dash-buttons-2">
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/deposit')}>
                   <img src={depositIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-90"/>
                   <p className="the-button-here-selected-text">Deposit</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/withdraw')}>
                   <img src={withdrawIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-270"/>
                   <p className="the-button-here-selected-text">Withdraw</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/transfer')}>
                   <img src={transferIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon the-button-here-selected-icon-active"/>
                   <p className="the-button-here-selected-text">Transfers / Trades</p> 
                 </div>
               </div>

               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/send')}>
                   <img src={sendIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon"/>
                   <p className="the-button-here-selected-text">Send</p> 
                 </div>
               </div>
              </div>

              <div className="filter-container">
                <label className="filter-label" htmlFor="balanceFilter">
                  Trans Type:
                </label>
                <select
                  id="balanceFilter"
                  className="filter-select"
                  value={transType}
                  onChange={(e) => setTransType(e.target.value)}
                >
                  <option value="deposit">Deposit</option>
                  <option value="withdraw">Withdraw</option>
                  <option value="swap">Swap</option>
                  <option value="send">Send</option>
                </select>
                <label className="filter-label" htmlFor="balanceFilter">Start Date:</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date-picker"
                />

                <label className="filter-label" htmlFor="balanceFilter">End Date:</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date-picker"
                />
              </div>
              
              <Table className="the-table-content">
                <Thead>
                  <Tr>
                      <Th>Crypto</Th>
                      <Th>Network</Th>
                      <Th>Type</Th>
                      <Th>Time</Th>
                      <Th>TxID</Th>
                      <Th>Destination</Th>
                      <Th>Status</Th>
                      <Th>Amount</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        Solana
                      </Td>
                      <Td>
                        <p>SOL</p>
                        <p>Sol network</p>
                      </Td>
                      <Td>Withdraw</Td>
                      <Td>19 April 2019</Td>
                      <Td><p className="the-wallet">f4184fc596403b9d638783cf57adfe4c75c605f6356fbc91338530e9831e9e16</p></Td>
                      <Td><p className="the-wallet">1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</p></Td>
                      <Td>Withdrawal Successful</Td>
                      <Td>62.56</Td>
                      <Td><span className="button">Details</span></Td>
                    </Tr>
                  </Tbody>
                </Table>
            </div>
          </div> 
        </div>
      </div>

    </div>
  );
}

export default Transactions;
