import CryptoJS from 'crypto-js';
import forge from "node-forge";

const thePublicKey = `-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBoUfHgaCfGWB0h8RkDDTDU
/EnXov4ZX1RThubH2EfI97VIY3T0A4NdNxySStLX459OWHSdGoaYA+RtENb8NM8e
H/rcmI0kxJKMXr1t6ZSPJTfcuDLqnELa4XWGh4Pb8gFqRHtA/ykwQYz+WT/dtEAE
ZIKBI9mdS5Cgm+iTU67EM3oa/78AnLCEKi0PCBPvasu3zrFZoPmMTe1EDIfstCiS
QM+/5l7n/uegls6DsCgW8gKE1iSomHz4kPDVdx4wSOGXVa2lp+Va3aPfUaBqp1Mg
ZK7WN9R71oIxz3MysaXZmuITsv4ZfQI4Fja5AZfWtIl7a1sUw35aJq1viU4ZD1oj
0l3X8ouZPxZRNNJeNvLNsKkxvnqT6idGPa6SA6O/W6Wfk3nxfiZHeBmkndYwxlwK
eZg34hQmAwxV2b9BKDUllAeycRti29lRHsok9e8nFe3EA74NF5ZQ1HvfQW0oNbdr
XSapnypQkBfl8ELc9Rj8NYKjB7Qrd9drQ0k+0+9J6F3zkzINlB12PBhjriGgdJeI
lMusviJ2Zg8P63ZfySIjZGHQa8PpnUvqy5Lib8wtlQqFL6vQJgQHSy42JiJxbTiA
MhVBOLb5Hgsn39yVW3aFQ5uWT8NF8+Cq0hyIV1UcNFhjFnQNRh/HXbF+b45PEqfP
0YjI0TLqgYrk5BkvA+s5MwIDAQAB
-----END PUBLIC KEY-----`;

// read from public key file
const encryptData = (data, secretKey = process.env.DATA_SECRET_KEY, iv = process.env.DATA_IV_KEY) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

// read from public key file
const getPublicKey = () => {
  try{
    return publicKey;
  }catch(e){
    console.log(e);
  }
};


// Generate the IV for each post made
const generateDataIV = () => {
  return forge.random.getBytesSync(16);
};

// encrypt with publoc key
const encryptWithPublicKey = (iv, publicKey) => {

  const pem = forge.pki.publicKeyFromPem(publicKey);
  const encryptedIV = pem.encrypt(iv, "RSA-OAEP", {
    md: forge.md.sha256.create(),
  });
  return forge.util.encode64(encryptedIV); 
};

const encryptPayLoad = async (data) => {

  // Loading public key
  const publicKey     = thePublicKey; 
  // Generate IV
  const iv            = await generateDataIV(); 

  // Encrypt IV with RSA
  const encryptedIV   = await encryptWithPublicKey(iv, publicKey); 

  const key = forge.util.createBuffer(iv); 
  const cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv });
  cipher.update(forge.util.createBuffer(JSON.stringify(data)));
  cipher.finish();
  return {
            iv: encryptedIV,
            data: forge.util.encode64(cipher.output.bytes())
         };
};
export { encryptPayLoad };

