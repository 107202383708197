import React from 'react';
import { Box, Container, Typography, Button, Divider, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  maxWidth: '480px !important',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '0.875rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.875rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px',
  borderRadius: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const ConfirmDeposit = () => {
    const nav = useNavigate();
  // This would come from your state management or route params
  const transactionDetails = {
    tokenToBuy: 'USDT',
    amount: '799.000',
    country: 'UGANDA',
    paymentMethod: 'Mobile Money',
    totalAmount: '50,000 UGX',
    fees: '1,500 UGX',
    rate: '3,800 UGX = 1 USDT',
    estimatedDelivery: '5-15 minutes',
  };
   const handleSubmit = () => {
        nav('/transaction-success', {
            state: {
            type: 'Purchase',
            amount: '799.00 USDT',
            reference: 'TRX-123456789'
            }
        });
   }
  return (
    <div className="App">
        <Header />

        <StyledContainer>
        <StyledPaper>
            {/* Header */}
            <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ color: '#000000', fontWeight: 600, mb: 0.5 }}>
                Confirm Transaction
            </Typography>
            <Typography variant="body2" sx={{ color: '#00C853' }}>
                Transaction Details
            </Typography>
            </Box>

            {/* Transaction Details Section */}
            <Box sx={{ mb: 3 }}>
            <DetailRow>
                <DetailLabel>Token To Buy</DetailLabel>
                <DetailValue>{transactionDetails.tokenToBuy}</DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>Amount</DetailLabel>
                <DetailValue>{transactionDetails.amount} USDT</DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>Country</DetailLabel>
                <DetailValue>{transactionDetails.country}</DetailValue>
            </DetailRow>
            
            <DetailRow>
                <DetailLabel>Payment Method</DetailLabel>
                <DetailValue>{transactionDetails.paymentMethod}</DetailValue>
            </DetailRow>

            <Divider sx={{ my: 1.5 }} />

            <DetailRow>
                <DetailLabel>Total Amount</DetailLabel>
                <DetailValue sx={{ color: '#00C853', fontWeight: 600 }}>
                {transactionDetails.totalAmount}
                </DetailValue>
            </DetailRow>

            <DetailRow>
                <DetailLabel>Network Fees</DetailLabel>
                <DetailValue>{transactionDetails.fees}</DetailValue>
            </DetailRow>

            <DetailRow>
                <DetailLabel>Exchange Rate</DetailLabel>
                <DetailValue>{transactionDetails.rate}</DetailValue>
            </DetailRow>

            <DetailRow>
                <DetailLabel>Estimated Delivery</DetailLabel>
                <DetailValue>{transactionDetails.estimatedDelivery}</DetailValue>
            </DetailRow>
            </Box>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <StyledButton
                variant="contained"
                fullWidth
                startIcon={<AccountBalanceWalletIcon />}
                sx={{
                backgroundColor: '#000000',
                '&:hover': { backgroundColor: '#1a1a1a' },
                }}
                onClick={() => handleSubmit()}
            >
                Buy Token
            </StyledButton>

            <StyledButton
                variant="outlined"
                fullWidth
                startIcon={<ArrowBackIcon />}
                sx={{
                borderColor: '#000000',
                color: '#000000',
                '&:hover': {
                    borderColor: '#000000',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                }}
            >
                Back
            </StyledButton>
            </Box>

            {/* Step Indicator */}
            <Typography 
            align="center" 
            sx={{ 
                color: '#00C853', 
                mt: 2,
                fontSize: '0.75rem',
                fontWeight: 500,
            }}
            >
            Step 2/3
            </Typography>

            {/* Disclaimer */}
            <Typography 
            variant="caption" 
            sx={{ 
                color: '#000000', 
                display: 'block', 
                textAlign: 'center',
                mt: 1.5,
                fontSize: '0.7rem',
            }}
            >
            By proceeding, you agree to our terms of service and privacy policy.
            </Typography>
        </StyledPaper>
        </StyledContainer>
        <div>
            <Footer />
        </div>
    </div>
  );
};

export default ConfirmDeposit; 