import React, { useEffect, useState, useRef } from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function PrivacyPolicy() {

  const targetRef = useRef(null);
  const [alertTriggered, setAlertTriggered] = useState(false);
  const [alertTriggeredx, setAlertTriggeredx] = useState(100);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("target-section");
      if (section) {
        const rect = section.getBoundingClientRect();
        setAlertTriggeredx(rect.top);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div className="App">

      <Header />
      <div className="privacy-policy-container">

      <h1>PeerPesa Data Privacy Policy</h1>
      <p className="effective-date">Effective Date: October 1, 2024</p>
      <p className="version">Version: 2.0</p>

      <section className="privacy-policy-container-content">
       
       <section   
          className={"the-menu-content-stick_ the-menu-content"} 
          ref={targetRef}
          id="target-section">

        <h2>Table of Contents</h2>
        <ul className="table-of-contents">
          <li><a href="#introduction">1. Introduction</a></li>
          <li><a href="#scope">2. Scope and Applicability</a></li>
          <li><a href="#definitions">3. Key Definitions</a></li>
          <li><a href="#principles">4. Privacy Principles</a></li>
          <li><a href="#collected-data">5. Types of Personal Information Collected</a></li>
          <li><a href="#legal-basis">6. Legal Basis for Processing Personal Data</a></li>
          <li><a href="#data-collection">7. How Personal Information is Collected</a></li>
          <li><a href="#purpose">8. Purpose of Data Collection</a></li>
          <li><a href="#sharing">9. How We Share Your Data</a></li>
          <li><a href="#international">10. International Data Transfers</a></li>
          <li><a href="#retention">11. Data Retention and Security</a></li>
          <li><a href="#rights">12. Your Data Protection Rights</a></li>
          <li><a href="#cookies">13. Cookies and Tracking Technologies</a></li>
          <li><a href="#minors">14. Minors’ Privacy</a></li>
          <li><a href="#marketing">15. Marketing and Communications</a></li>
          <li><a href="#third-party">16. Third-Party Services and Links</a></li>
          <li><a href="#dispute-resolution">17. Dispute Resolution and Complaints</a></li>
          <li><a href="#policy-updates">18. Policy Updates and Revisions</a></li>
          <li><a href="#contact">19. Contact Information</a></li>
        </ul>
      </section>

      <section  className={"the-body-content-stick_ the-body-content"} >
            <section id="introduction">
              <h2 className="section-title">1. Introduction</h2>
              <p>
                PeerPesa Technologies Limited ("PeerPesa," "we," or "our") is committed to safeguarding your privacy and handling your personal data responsibly. As a data controller, PeerPesa complies with all applicable privacy laws, including the General Data Protection Regulation (GDPR) for our European users, and the respective data protection laws of Kenya, Uganda, Nigeria, Tanzania, and Ghana.
              </p>
              <p>
                Our commitment to data protection extends to all jurisdictions in which we operate, ensuring compliance with:
              </p>
              <ul className="compliance-list">
                <li><strong>Kenya:</strong> Data Protection Act, 2019</li>
                <li><strong>Uganda:</strong> Data Protection and Privacy Act, 2019</li>
                <li><strong>Nigeria:</strong> Nigeria Data Protection Regulation (NDPR), 2019, and Nigeria Data Protection Act, 2023</li>
                <li><strong>Tanzania:</strong> Electronic and Postal Communications (Online Content) Regulations, 2020</li>
                <li><strong>Ghana:</strong> Data Protection Act, 2012 (Act 843)</li>
              </ul>
              <p>If you have any inquiries regarding how your personal information is used or need to exercise your privacy rights, please contact our Data Protection Officer (DPO) at:</p>
              <ul className="contact-info">
                <li><strong>Email:</strong> <a href="mailto:privacy@peerpesa.co">privacy@peerpesa.co</a></li>
                <li><strong>Address:</strong> <a href="https://peerpesa.co" target="_blank" rel="noopener noreferrer">https://peerpesa.co</a></li>
              </ul>
            </section>

            <section id="scope">
              <h2>2. Scope and Applicability</h2>
              <p>
                This Privacy Policy applies to all personal data collected, processed, and stored through PeerPesa’s services, including:
              </p>
              <ul className="applicability-list">
                <li>Our website (<a href="https://www.peerpesa.co" target="_blank" rel="noopener noreferrer">www.peerpesa.co</a>)</li>
                <li>Mobile applications</li>
                <li>Application Programming Interfaces (APIs)</li>
                <li>Communication tools (e.g., email notifications)</li>
                <li>Any other services that link to this Privacy Policy</li>
              </ul>
              <p>
                It governs how PeerPesa collects, uses, stores, shares, and protects personal data related to customers, employees, contractors, and partners.
              </p>
            </section>

            <section id="definitions">
              <h2>3. Key Definitions</h2>
              <ul>
                  <li>
                    <strong>Personal Data:</strong> Any information related to an identifiable individual, such as name, email,
                    financial information, or location data.
                  </li>
                  <li>
                    <strong>Special Categories of Data:</strong> Sensitive data, including race, health information, biometric
                    data, or religious beliefs, which require extra protection.
                  </li>
                  <li>
                    <strong>Data Controller:</strong> The entity that determines the purpose and means of processing personal
                    data.
                  </li>
                  <li>
                    <strong>Data Processor:</strong> A third party that processes personal data on behalf of the controller.
                  </li>
                  <li>
                    <strong>Cookies:</strong> Small files stored on devices to track user activities and preferences.
                  </li>
                  <li>
                    <strong>Data Subject:</strong> An individual whose personal data is processed by a data controller or data
                    processor.
                  </li>
                </ul>
            </section>


            {/* Add more sections as needed, for example: */}
            <section id="principles">
              <h2>4. Privacy Principles</h2>
              <p>At PeerPesa, we adhere to the following key principles, reflecting internationally recognized data protection standards:</p>
              <ul>
                <li><strong>Lawfulness, Fairness, and Transparency:</strong> We process personal data lawfully, fairly, and transparently.</li>
                <li><strong>Purpose Limitation:</strong> Personal data is collected for specified, explicit, and legitimate purposes and not further processed incompatibly.</li>
                <li><strong>Data Minimization:</strong> We collect only the necessary data for specific purposes.</li>
                <li><strong>Accuracy:</strong> We ensure personal data is accurate and up-to-date.</li>
                <li><strong>Storage Limitation:</strong> Personal data is kept no longer than necessary.</li>
                <li><strong>Integrity and Confidentiality:</strong> We process personal data securely to prevent unauthorized access or breaches.</li>
                <li><strong>Accountability:</strong> We are responsible for compliance with these principles.</li>
              </ul>
            </section>

            <section id="collected-data">
              <h2>5. Types of Personal Information Collected</h2>
              <p>PeerPesa may collect the following types of personal information:</p>
              <ul>
                <li><strong>Identity Data:</strong> Full name, date of birth, national ID, passport, driver's license, or other government-issued identification.</li>
                <li><strong>Contact Data:</strong> Email address, phone number, mailing address, and emergency contact information.</li>
                <li><strong>Financial Data:</strong> Bank account details, cryptocurrency wallet information, transaction history, payment card details.</li>
                <li><strong>Usage Data:</strong> IP addresses, device details (including device IDs and types), login information, browsing history on our platform, interaction with our services.</li>
                <li><strong>Communications Data:</strong> Records of customer support interactions, feedback, survey responses.</li>
                <li><strong>Marketing Preferences:</strong> Information about subscriptions, opt-in or opt-out preferences, and communication preferences.</li>
                <li><strong>Location Data:</strong> Geolocation information derived from IP addresses or GPS data, subject to user consent where required.</li>
              </ul>
            </section>

            <section id="legal-basis">
              <h2>6. Legal Basis for Processing Personal Data</h2>
              <p>PeerPesa processes personal data based on the following lawful grounds, as applicable under the data protection laws of Kenya, Uganda, Nigeria, Tanzania, Ghana, and other jurisdictions where we operate:</p>
              <ul>
                <li><strong>Consent:</strong> When users provide explicit consent for specific purposes, such as opting in to receive marketing communications.</li>
                <li><strong>Performance of a Contract:</strong> To provide services and fulfill contractual obligations, such as processing cryptocurrency transactions or providing customer support.</li>
                <li><strong>Legal Obligation:</strong> To comply with statutory and regulatory obligations, including Anti-Money Laundering (AML) laws, Combating the Financing of Terrorism (CFT) regulations, tax laws, and other legal requirements.</li>
                <li><strong>Legitimate Interests:</strong> For purposes necessary for our legitimate interests or those of a third party, such as fraud prevention, network and information security, or improving our services, provided that such interests are not overridden by the data subject's rights and interests.</li>
                <li><strong>Public Interest:</strong> Processing necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.</li>
              </ul>
            </section>

            
            <section id="data-collection">
              <h2>7. How Personal Information is Collected</h2>
              <p>We collect personal data directly from you and from third parties in the following ways:</p>
              <ul>
                <li><strong>Direct Interactions:</strong>
                  <ul>
                    <li><strong>Account Registration:</strong> When you create an account or update your profile.</li>
                    <li><strong>Service Usage:</strong> When you use PeerPesa services, such as initiating cryptocurrency transactions, depositing, or withdrawing funds.</li>
                    <li><strong>Customer Support:</strong> When you engage with our customer support team via email, chat, or phone.</li>
                    <li><strong>Surveys and Feedback:</strong> When you participate in surveys, promotions, or provide feedback.</li>
                  </ul>
                </li>
                <li><strong>Automated Technologies:</strong>
                  <ul>
                    <li><strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and similar technologies to collect data about your interactions with our website and services.</li>
                    <li><strong>Device and Usage Data:</strong> We automatically collect technical data about your device, browsing actions, and patterns.</li>
                  </ul>
                </li>
                <li><strong>Third Parties and Public Sources:</strong>
                  <ul>
                    <li><strong>Verification Services:</strong> From third-party KYC and AML verification providers.</li>
                    <li><strong>Affiliates and Partners:</strong> From other companies within the PeerPesa group or partners who have obtained your consent to share information with us.</li>
                    <li><strong>Public Databases:</strong> From publicly available sources as permitted by law.</li>
                  </ul>
                </li>
              </ul>
            </section>

            <section id="purpose">
              <h2>8. Purpose of Data Collection</h2>
              <p>We collect and process your personal data for the following purposes:</p>
              <ul>
                <li><strong>Identity Verification:</strong> To verify your identity and comply with KYC and AML/CFT requirements as mandated by law.</li>
                <li><strong>Service Provision:</strong> To provide, maintain, and improve our cryptocurrency services, including processing transactions, facilitating payments, and providing customer support.</li>
                <li><strong>Compliance:</strong> To comply with legal and regulatory obligations, including reporting to regulatory authorities, responding to lawful requests, and maintaining records.</li>
                <li><strong>Fraud Prevention and Security:</strong> To detect, prevent, and mitigate fraudulent or illegal activities, and to ensure the security of our systems and your data.</li>
                <li><strong>Service Improvement:</strong> To analyze how our services are used, evaluate effectiveness, and develop new features and services.</li>
                <li><strong>Communication:</strong> To communicate important updates, security alerts, and administrative messages related to your account or transactions.</li>
                <li><strong>Marketing and Promotions:</strong> To send you promotional materials, newsletters, and information about products, services, or events that may interest you, subject to your consent.</li>
                <li><strong>Personalization:</strong> To personalize your experience and deliver content and products relevant to your interests.</li>
              </ul>
            </section>

            <section id="sharing">
              <h2>9. How We Share Your Data</h2>
              <p>PeerPesa shares personal data only when necessary and under strict conditions, in compliance with applicable laws:</p>
              <ul>
                <li><strong>With Your Consent:</strong> We share your personal data with third parties when you have provided explicit consent.</li>
                <li><strong>Service Providers:</strong> With third-party vendors, consultants, and service providers who need access to personal data to perform services on our behalf.</li>
                <li><strong>Affiliates and Subsidiaries:</strong> With our group companies and affiliates to facilitate our global operations.</li>
                <li><strong>Legal Obligations:</strong> To comply with legal obligations and protect our rights.</li>
                <li><strong>Business Transfers:</strong> In case of mergers, acquisitions, or business sales.</li>
              </ul>
            </section>


            <section id="international">
              <h2>10. International Data Transfers</h2>
              <p>As a global service provider, PeerPesa may transfer your personal data to countries outside of your jurisdiction, including countries that may not have the same level of data protection laws as your own country.</p>
              <p>We ensure such transfers are conducted in compliance with applicable data protection laws, and that appropriate safeguards are in place, including:</p>
              <ul>
                <li><strong>Standard Contractual Clauses (SCCs):</strong> We use SCCs approved by the European Commission and other relevant authorities to ensure adequate protection for data transferred outside the European Economic Area (EEA).</li>
                <li><strong>Data Processing Agreements (DPAs):</strong> We have DPAs with our third-party service providers that include obligations to comply with data protection standards.</li>
                <li><strong>Adequacy Decisions:</strong> Where applicable, we rely on adequacy decisions by relevant authorities recognizing that the destination country provides an adequate level of data protection.</li>
                <li><strong>Binding Corporate Rules (BCRs):</strong> For intra-group transfers, we may use BCRs approved by relevant data protection authorities.</li>
              </ul>
              <p>You may request more information about the safeguards we have in place for international data transfers by contacting us at privacy@peerpesa.co.</p>
            
            </section>


            <section id="retention">
              <h2>11. Data Retention and Security</h2>
              <h3>Data Retention</h3>
              <p>PeerPesa retains personal data only for as long as necessary to fulfill the purposes for which it was collected, including:</p>
              <ul>
                <li><strong>Legal and Regulatory Compliance:</strong> To comply with our legal obligations, such as record-keeping for AML/CFT regulations, tax laws, and other statutory requirements.</li>
                <li><strong>Dispute Resolution:</strong> To resolve disputes or enforce our agreements.</li>
                <li><strong>Business Needs:</strong> For legitimate business purposes, such as maintaining records of transactions.</li>
              </ul>
              <p>When personal data is no longer necessary, we securely delete or anonymize it in accordance with applicable laws and regulations.</p>
              
              <h3>Data Security</h3>
              <p>We implement robust security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction, including:</p>
              <ul>
                <li><strong>Encryption:</strong> Personal data is encrypted during transmission over public networks and at rest where appropriate.</li>
                <li><strong>Access Control:</strong> Access to personal data is restricted to authorized personnel who require it to perform their job functions and are subject to confidentiality obligations.</li>
                <li><strong>Firewalls and Intrusion Detection:</strong> We use firewalls and intrusion detection systems to protect our systems from unauthorized access.</li>
                <li><strong>Regular Audits and Testing:</strong> We conduct regular security assessments, vulnerability scans, and penetration testing to identify and mitigate potential threats.</li>
                <li><strong>Data Breach Response:</strong> We have a data breach response plan in place and will notify affected individuals and relevant authorities as required by law in the event of a data breach.</li>
              </ul>
              <p>While we strive to protect your personal data, no security system is impenetrable. We encourage you to also take steps to protect your information by using strong passwords, keeping your account credentials confidential, and logging out after use.</p>

            </section>


            
            <section id="rights">
              <h2>12. Your Data Protection Rights</h2>
              <p>Depending on your jurisdiction, you may have certain rights under applicable data protection laws. These rights may include:</p>
              <ul>
                <li><strong>Right to Access:</strong> Request confirmation on whether we process your personal data and obtain a copy.</li>
                <li><strong>Right to Rectification:</strong> Request correction of inaccurate or incomplete personal data.</li>
                <li><strong>Right to Erasure ("Right to be Forgotten"):</strong> Request deletion of your personal data under certain circumstances.</li>
                <li><strong>Right to Restrict Processing:</strong> Request limitation on the processing of your personal data under certain conditions.</li>
                <li><strong>Right to Data Portability:</strong> Receive your personal data in a structured, commonly used format and transfer it to another controller.</li>
                <li><strong>Right to Object:</strong> Object to the processing of your personal data for specific purposes, such as direct marketing or profiling.</li>
                <li><strong>Right to Withdraw Consent:</strong> Withdraw your consent at any time if processing is based on consent.</li>
                <li><strong>Right to Lodge a Complaint:</strong> File a complaint with a supervisory authority if your data protection rights are violated.</li>
              </ul>
              <h3>Exercising Your Rights</h3>
              <p>To exercise any of these rights, please contact us at <a href="mailto:privacy@peerpesa.co">privacy@peerpesa.co</a>. We may need to verify your identity before processing your request and will respond within the legal timeframes.</p>
            </section>


            
            <section id="cookies">
              <h2>13. Cookies and Tracking Technologies</h2>
              <p>
              We use cookies and similar tracking technologies to collect information about your interactions with our website and services.
            </p>
            <h2 className="text-xl font-semibold mt-4">Types of Cookies We Use:</h2>
            <ul className="list-disc pl-5 mt-2">
              <li><strong>Essential Cookies:</strong> Necessary for website operation and secure logins.</li>
              <li><strong>Performance & Analytics Cookies:</strong> Help us analyze traffic and improve functionality.</li>
              <li><strong>Functionality Cookies:</strong> Recognize returning users for personalized content.</li>
              <li><strong>Advertising & Targeting Cookies:</strong> Track visited pages to deliver relevant ads.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">Managing Cookies</h2>
            <p className="mt-2">
              You can manage or disable cookies through your browser settings. However, disabling certain cookies may affect the functionality of our website and services.
            </p>
            <p className="mt-2">
              For more information on how we use cookies and how you can manage them, please see our <a href="/cookie-policy" className="underline">Cookie Policy</a>.
            </p>
            </section>


            
            <section id="minors">
              <h2>14. Minors’ Privacy</h2>
              <h3 className="text-lg font-semibold">Age Restriction Policy</h3>
              <p className="text-gray-700 mt-2">
                PeerPesa services are intended for individuals aged 18 and above. We do not knowingly collect personal data from minors. If we discover that we have inadvertently collected such data, we will promptly delete it.
              </p>
              <p className="text-gray-700 mt-2">
                Parents or guardians who believe their child has provided us with personal data without consent should contact us at{" "}
                <a href="mailto:privacy@peerpesa.co" className="text-blue-600 underline">
                  privacy@peerpesa.co
                </a>{" "}
                to request its removal.
              </p>
            </section>



            
            <section id="marketing">
              
              <h2>15. Marketing and Communications</h2>
              <h3 className="text-lg font-semibold">Direct Marketing</h3>
              <p className="text-gray-700 mt-2">
                We may use your personal data to send you direct marketing communications about our products, 
                services, or events that may be of interest to you. We will obtain your consent where required by law.
              </p>

              <h3 className="text-md font-semibold mt-4">Opting Out</h3>
              <p className="text-gray-700 mt-2">You can opt out of receiving marketing communications at any time by:</p>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li>Clicking the <strong>"unsubscribe"</strong> link in our marketing emails.</li>
                <li>Updating your communication preferences in your account settings.</li>
                <li>
                  Contacting us at{" "}
                  <a href="mailto:privacy@peerpesa.co" className="text-blue-600 underline">
                    privacy@peerpesa.co
                  </a>.
                </li>
              </ul>
              <p className="text-gray-700 mt-2">
                Even if you opt out of marketing communications, we may still send you 
                non-promotional messages related to your account or transactions.
              </p>

            </section>


            
            <section id="third-party">
              <h2>16. Third-Party Services and Links</h2>
              <p className="text-gray-700 mt-2">
                Our platform may contain links to third-party websites, plug-ins, or applications. Clicking on those
                links or enabling those connections may allow third parties to collect 
                or share data about you. 
              </p>
              <p>
                We do not control these third-party websites and are not responsible for their privacy practices. We
                encourage you to read the privacy policies of every website you visit.
              </p>
            </section>


            
            <section id="dispute-resolution">
              <h2>17. Dispute Resolution and Complaints</h2>

              <h3 className="text-lg font-semibold">Data Protection Complaints</h3>
              <p className="text-gray-700 mt-2">
                If you have any concerns or complaints regarding how we handle your personal data, 
                please contact us at{" "}
                <a href="mailto:privacy@peerpesa.co" className="text-blue-600 underline">
                  privacy@peerpesa.co
                </a>. 
                We take all complaints seriously and will respond promptly in accordance with applicable laws.
              </p>

              <p className="text-gray-700 mt-2">
                If you are not satisfied with our response, you may have the right to lodge a complaint with 
                the data protection authority in your jurisdiction.
              </p>

              <h3 className="text-md font-semibold mt-4">Contact Details of Data Protection Authorities:</h3>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li><strong>Kenya:</strong> Office of the Data Protection Commissioner (ODPC)</li>
                <li><strong>Uganda:</strong> Personal Data Protection Office (PDPO)</li>
                <li><strong>Nigeria:</strong> Nigeria Data Protection Bureau (NDPB)</li>
                <li><strong>Ghana:</strong> Data Protection Commission (DPC)</li>
                <li><strong>Tanzania:</strong> Tanzania Communications Regulatory Authority (TCRA)</li>
              </ul>
            </section>

            
            <section id="policy-updates">
              <h2>18. Policy Updates and Revisions</h2>
              <p className="text-gray-700 mt-2">
                We may update this Privacy Policy from time to time to reflect changes in our practices, 
                technologies, legal requirements, or other factors. When we make significant changes, we will notify you by:
              </p>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li>Posting the updated policy on our website with a new effective date.</li>
                <li>Sending you an email or notification if you have an active account with us.</li>
              </ul>
              <p className="text-gray-700 mt-2">
                We encourage you to review this Privacy Policy periodically to stay informed about how we protect your personal data.
              </p>
              
            </section>


                <section id="contact">
                  <h2>19. Contact Information</h2>
                  <p className="text-gray-700 mt-2">
                    If you have questions, concerns, or requests regarding this Privacy Policy or our data practices, 
                    please contact our Data Protection Officer:
                  </p>

                  <ul className="list-disc list-inside text-gray-700 mt-2">
                    <li>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:privacy@peerpesa.co" className="text-blue-600 underline">
                        privacy@peerpesa.co
                      </a>
                    </li>
                    <li>
                      <strong>Website:</strong>{" "}
                      <a href="https://peerpesa.co" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">
                        peerpesa.co
                      </a>
                    </li>
                  </ul>

                <p className="text-gray-700 mt-2">
                  This Data Privacy Policy reflects our commitment to protecting your personal data and ensuring transparency 
                  in how it is handled. We encourage all users to review this policy regularly to stay informed about how their data is managed.
                </p>
              </section>

            </section>
          </section>
          </div>




      
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
