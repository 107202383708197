import axios from "axios";
import { encryptPayLoad } from '../../helpers/encryption';


// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL from the .env file
  headers: {
    "Content-Type":  "application/json"
  },
});

/**
 * Handle API response.
 * @param {Response} response - Axios response object.
 * @returns {any} - Parsed response data.
 */
const handleResponse = (response) => {
  return response.data;
};

/**
 * Handle API errors.
 * @param {Error} error - Error object.
 * @returns {Promise<Error>} - A rejected promise with error details.
 */
const handleError = (error) => {
  const message =
    error.response?.data?.message || // Server error message
    error.message ||                // Network or Axios error message
    "An unknown error occurred.";
  console.error("API Error:", message); // Log the error for debugging
  return Promise.reject(new Error(message));
};



/**
 * GET Request
 * @param {string} endpoint - API endpoint.
 * @returns {Promise<any>}
 */
export const getRequest = (endpoint, data) => {
  
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${data?.authToken}`;
  //alert(data?.authToken)
  return apiClient
    .get(endpoint)
    .then(handleResponse)
    .catch(handleError);
};

/**
 * POST Request
 * @param {string} endpoint - API endpoint.
 * @param {Object} data - Data to send.
 * @returns {Promise<any>}
 */
export const postRequest = async (endpoint, data) => {
  
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${data?.authToken}`;
  const payLoad = await encryptPayLoad(data);
  return apiClient
    .post(endpoint, payLoad)
    .then(handleResponse)
    .catch(handleError);
};

/**
 * Edit
 * @param {string} endpoint - API endpoint.
 * @param {Object} data - Data to update.
 * @returns {Promise<any>}
 */
export const putRequest = async (endpoint, data) => {
  
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${data?.authToken}`;
  const payLoad = await encryptPayLoad(data);
  return apiClient
    .put(endpoint, payLoad)
    .then(handleResponse)
    .catch(handleError);
};

/**
 * Edit Request
 * @param {string} endpoint - API endpoint.
 * @param {Object} data - Partial data to update.
 * @returns {Promise<any>}
 */
export const patchRequest = async (endpoint, data) => {
  
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${data?.authToken}`;
  const payLoad = await encryptPayLoad(data);
  return apiClient
    .patch(endpoint, payLoad)
    .then(handleResponse)
    .catch(handleError);
};

/**
 * DELETE Request
 * @param {string} endpoint - API endpoint.
 * @returns {Promise<any>}
 */
export const deleteRequest = (endpoint) => {
  
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${data?.authToken}`;
  return apiClient
    .delete(endpoint)
    .then(handleResponse)
    .catch(handleError);
};
