import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { RotatingLines, Rings, Oval } from "react-loader-spinner";
import { passwordTokenRequest, resetPasswordRequest} from '../requests/requests'
import { toast } from 'react-toastify';


import '../styles/TransferForm.css';

const ResetPasswordTransferForm = () => {

  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [resendToken, setResendToken] = useState(false);
  const [resendTokenText, setResendTokenText] = useState(false);
  const [formTokenLoading, setFormTokenLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [componentData, setComponentData] = useState({
                                                      currencyOptions: [],
                                                      buyCurrencyFilter: [],
                                                      sellCurrencyFilter: [],
                                                      currencyCountryFlagsCollection: [],
                                                      data: {
                                                              email: "",
                                                              password: "",
                                                              token: "",
                                                              confirm_password: ""
                                                            }
                                                    });   

  // Custom option label with image and text
  const formatOptionLabel = ({ label, imageUrl }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={imageUrl} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
        <span>{label}</span>
      </div>
  );



  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  
    const resetPassword = useMutation({
      mutationFn: (payLoad) => {
        setFormLoading(true);
        return resetPasswordRequest(payLoad);
      },
      onError: (error, variables, context) => {
        
        setFormLoading(false);
        const sendMsg = (error?.message !== undefined)? error?.message: "failed to place request. confirm network connection";
        return toast.error(sendMsg);
      },
      onSuccess: (data, variables, context) => {
  
        setFormLoading(false);
        if(data.status === false){
          
          return toast.error(data?.message);
        } else {
          
          setComponentData((prevState) => ({
            ...prevState,
            data: {
                  ...prevState.data,
                  email: "",
                  password: "",
                  token: "",
                  confirm_password: ""
                }
          }));
          return toast.success(data?.message);
        }
  
      },
    });
    
    const onUserPasswordReset = (creds) => {
      resetPassword.mutate(creds);
    };

    const resetPasswordToken = useMutation({
      mutationFn: (payLoad) => {
        setResendToken(true);
        return passwordTokenRequest(payLoad);
      },
      onError: (error, variables, context) => {
        
        setResendToken(false);
        setResendTokenText(true);

        const sendMsg = (error?.message !== undefined)? error?.message: "failed to place request. confirm network connection";
        return toast.error(sendMsg);

      },
      onSuccess: (data, variables, context) => {
  
        setResendToken(false);
        setResendTokenText(true);
        if(data.status === false || data.status === 'error'){
          
          return toast.error(data?.message);
        } else {

          return toast.success(data?.message);
        }
  
      },
    });
    
    const onUserPasswordResetToken = (creds) => {
      creds.type = "email";
      resetPasswordToken.mutate(creds);
    };

    const contentChange= (e, theKey, trimSpace = false) => {
      try{
        
        let { value } = e.target;
        if(trimSpace){
            value = value.trim().replace(/\s+/g, '');
        }
        setComponentData((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [theKey]: value,
          },
        }));
  
      } catch(e){
        console.log(e)
      }
    }
    
  return (

    <div className="card-login">
      <form className="form">

        <div className="text-center-content">
           <h3 className="text-center">Reset Your PeerPesa Account Password</h3> 
        </div>   


        <div className="form-inputs">
          <label> Email  </label>
          <div className="selection-payment-method clearfix group">
            <input type="text" 
                   className="selection-other"
                   value={componentData.data.email}
                   placeholder="Enter your email"
							     name="email"
                   {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                   })}
                   onChange={(e) => contentChange(e, 'email', true)}  />
          </div>
          {errors.email?.type === "required" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
          {errors.email?.type === "pattern" && (<span className="main-input-error">
            {errors.email.message}
          </span>)}
        </div>

        <div className="form-inputs">
          <label> Verification Token  </label>
          <div className="flexing_content flex_container reset-token-width">

            <div className="selection-payment-method clearfix group width-40">
              <input  type="text" 
                      className="selection-other"
                      value={componentData.data.token}
                      placeholder="Enter reset token"
                      name="token"
                      {...register("token", {
                        required: false
                      })}
                      onChange={(e) => contentChange(e, 'token')}  />
            </div>
           
            <button className="send-button width-55" onClick={handleSubmit(onUserPasswordResetToken)}>
              <span  className={(resendToken)? 'hidden':''}>
                {resendTokenText? 'Resend Token':'Send Token'}
              </span>
           
              <Oval
                   visible={resendToken}
                   height="20"
                   width="20"
                   color="#ffffff"
                   ariaLabel="oval-loading"
                   wrapperStyle={{}}
                   wrapperClass=""
                  />   
            </button>
            {errors.token?.type === "required" && (
							<span className="main-input-error clearfix">
                Reset token is required
							</span>
						)}
          </div>
        </div>

       {resendTokenText? <>
          
        <div className="form-inputs">
          <label> New Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" 
                   className="selection-other"
                   value={componentData.data.password}
                   placeholder="Enter password"
                   name="password"
                   {...register("password", {
                     required: false
                   })}
                   onChange={(e) => contentChange(e, 'password')}  />
          </div>
          {errors.password?.type === "required" && (
							<span className="main-input-error clearfix">
                Password is required
							</span>
						)}
        </div>
        
        <div className="form-inputs">
          <label> Confirm Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" 
                   className="selection-other"
                   value={componentData.data.confirm_password}
                   placeholder="Enter password"
                   name="confirm_password"
                   {...register("confirm_password", {
                     required: false
                   })}
                   onChange={(e) => contentChange(e, 'confirm_password')}  />
          </div>
          {errors.confirm_password?.type === "required" && (
							<span className="main-input-error clearfix">
                Confirm password is required
							</span>
						)}
        </div>

        <button className="send-button" onClick={handleSubmit(onUserPasswordReset)}>
              <span  className={(formLoading)? 'hidden':''}>
                 Save Password
              </span>
              <Oval
                   visible={formLoading}
                   height="20"
                   width="20"
                   color="#ffffff"
                   ariaLabel="oval-loading"
                   wrapperStyle={{}}
                   wrapperClass=""
                  />  
        </button>

       
       </>:''} 
        
        <div className="text-center-content-2">
           <p className="text-center">
                Have an Account Already? <Link to="/login" className="nav-link">Login here</Link>
           </p> 
        </div>



        <div className="text-center-content-2">
           <p className="text-center">
               &copy; PeerPesa 2024 All Rights Reserved
           </p> 
        </div>

        
      </form>
    </div>
  );
};

export default ResetPasswordTransferForm;
