import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SumsubWebSdk from '@sumsub/websdk-react';
import { toast } from 'react-toastify';
import '../styles/MainSection.css';
import backIcon from '../assets/back.png';
import { getKycAccessToken } from '../requests/requests';
import { getDataStorage } from '../helpers/storage';

function KycConfirmVerification() {
  const navigate = useNavigate();
  const [kycAccessToken, setKycAccessToken] = useState('');
  const [kycProcessingStatus, setKycProcessingStatus] = useState('');
  const [loggedUser, setLoggedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch data when the component is mounted
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user = await getDataStorage('loggedUser', false, true);
      const userToken = await getDataStorage('authToken', false, false);
      setLoggedUser(user);

      const accessTokenDetails = await getKycAccessToken({ email: user?.email, authToken: userToken });
      
      if (accessTokenDetails.status === false || accessTokenDetails.status === 'error') {
        toast.error(accessTokenDetails?.message);
      } else {
        if (accessTokenDetails?.application_token === undefined) {
          setKycProcessingStatus('processing');
        } else {
          setKycAccessToken(accessTokenDetails?.application_token);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  // Handle token expiration
  const handleTokenExpiration = async (refresh) => {
    const newToken = await getKycAccessToken({ email: loggedUser?.email });
    refresh(newToken?.application_token);
  };

  return (
    <div className="App kyc-verify-block">
      <div className="close-back clearfix group" onClick={() => navigate('/profile')}>
        <div className="close-back-icon">
          <img src={backIcon} alt="Back" />
          <p>Back to profile</p>
        </div>
      </div>

      {/* Loading or KYC Processing Status */}
      {kycProcessingStatus ? (
        <div className="loading-blocking-2">
          <h6>Your profile KYC is undergoing the verification process.</h6>
          <p>An email will be sent when the process is complete.</p>
        </div>
      ) : (
        loading ? (
          <div className="loading-blocking">
            <p>Loading verification...</p>
          </div>
        ) : (
          <SumsubWebSdk
            accessToken={kycAccessToken}
            expirationHandler={handleTokenExpiration}
            onMessage={(message) => console.log('Sumsub Message:', message)}
            onError={(error) => console.error('Sumsub Error:', error)}
            options={{
              lang: 'en',
              country: 'uga',
              email: loggedUser?.email,
              theme: 'light',
              controlledNavigationBack: true,
            }}
          />
        )
      )}
    </div>
  );
}

export default KycConfirmVerification;
